import { ProjectContext, TProjectContext } from "context/ProjectProvider";
import { FEATURE_ATTACHMENTS } from "entity-app/graphQL/ciq-feature-queries";
import { getGQLQueryData } from "entity-app/services";
import { hasTheFeature } from "entity-app/utils/utils";
import debounce from "lodash.debounce";
import moment from "moment";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { GET_COMMENTS } from "services/graphQL/ciq-queries";

export const useFeatureAttachments = (
  featureId: string,
  featureKey: string
) => {
  const { tokenRetrievalState, eventLogs }: TProjectContext =
    useContext(ProjectContext);
  const token = tokenRetrievalState?.token;

  const [attachments, setAttachments] = useState<any>();

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>();

  const getAttachments = useCallback(async () => {
    setLoading(true);
    if (!token) {
      return;
    }
    const attachmentsCall = getGQLQueryData(token, FEATURE_ATTACHMENTS, {
      where: {
        [featureKey]: { _eq: featureId }
      }
    });
    const commentsCall = getGQLQueryData(token, GET_COMMENTS, {
      where: {
        [featureKey]: { _eq: featureId }
      }
    });

    const responses = await Promise.all([attachmentsCall, commentsCall]);

    if (!responses[0].success || !responses[1].success) {
      setLoading(false);
      setError(responses[0].errorMsg || responses[1].errorMsg);
      return;
    }

    const featureAttachments = responses[0]?.data?.data?.attachments || [];
    const comments = responses[1]?.data?.data?.comment || [];

    const allCommentAttachments: any = [];
    comments?.forEach((comment: any) => {
      const attachmentInfo = comment.attachments?.map((attach: any) => {
        return {
          id: attach.id,
          file_name: attach.file_name,
          created_at: comment.created_at,
          created_by_user: comment.created_by_user,
          blob_key: attach.blob_key,
          file_type: attach.file_type
        };
      });
      allCommentAttachments.push(...attachmentInfo);
    });

    const mergedAttachments = [...allCommentAttachments, ...featureAttachments];

    const sortedAttachments = mergedAttachments.sort((a: any, b: any) => {
      return moment(b.created_at).diff(moment(a.created_at));
    });

    setAttachments(sortedAttachments);
    setLoading(false);
  }, [token, featureId, featureKey]);

  const debouncedGetAttachmentsRef: any = useRef<(() => void) | null>(null);

  useEffect(() => {
    debouncedGetAttachmentsRef.current = debounce(getAttachments, 3000);
    return () => {
      debouncedGetAttachmentsRef.current?.cancel();
    };
  }, [getAttachments]);

  useEffect(() => {
    getAttachments();
  }, [getAttachments]);

  // Poll the changes and update the respective UI components
  const previousEventLogs = useRef(eventLogs);

  useEffect(() => {
    if (eventLogs?.length && previousEventLogs.current !== eventLogs) {
      const isCommentOrAttachmentEvent = eventLogs.some((log) => {
        const info: any = log?.info;
        const isCommnetOrAttachmentChanged =
          log?.data_source === "comment" ||
          log?.data_source === "attachments" ||
          info?.table_name === "comment" ||
          info?.table_name === "attachments";
        const isRelatedToCurrentFeature: boolean = hasTheFeature(
          featureId,
          log,
          featureId
        );

        return isCommnetOrAttachmentChanged && isRelatedToCurrentFeature;
      });

      if (isCommentOrAttachmentEvent) {
        debouncedGetAttachmentsRef.current?.();
      }
    }
    previousEventLogs.current = eventLogs;
  }, [eventLogs, featureId, getAttachments]);

  return { attachments, loading, error };
};
