const SUBMITTAL_BY_PK = `
  SubmittalSubscription($id: uuid!) {
    submittals_by_pk(id: $id) {
      revision
      submittal_revisions
      ROJ
      assignee
      submittal_assignee {
        email
        first_name
        id
        last_name
        project_users {
          status_id
          subscription_vendor {
            name
          }
          subscription {
            organization_subscriptions {
              organization {
                name
              }
            }
          }
        }
      }
      created_at
      due_date
      description
      id
      issue_date
      organization
      revision
      spec_section_id
      spec_no
      spec_name
      material_tracking
      submittal_manager
      submittal_manager_user {
        email
        first_name
        id
        last_name
        project_users {
          subscription {
            organization_subscriptions {
              organization {
                name
              }
            }
          }
        }
      }
      received_from
      received_from_user {
        email
        first_name
        last_name
        id
        project_users {
          subscription_vendor {
            name
          }
        }
      }
      status
      workflow_status
      submittals_status {
        id
        status
      }
      title
      type
      raw_type
      raw_status
      submittal_id
      submittal_links_source {
        id
        target_id
      }
      design_reviewer
      design_reviewer_user {
        email
        first_name
        id
        last_name
        project_users {
          status_id
          subscription_vendor {
            name
          }
        }
      }
      gc_reviewer
      gc_reviewer_user {
        email
        first_name
        id
        last_name
        project_users {
          status_id
          subscription {
            organization_subscriptions {
              organization {
                name
              }
            }
          }
        }
      }
      submitter
      submitter_user {
        email
        first_name
        last_name
        id
        project_users {
          status_id
          subscription_vendor {
            name
          }
        }
      }
      responsible_contractor
      responsible_contractor_vendor {
        id
        name
      }
      submittal_status_history_details(order_by: { created_at: desc }) {
        id
        status
        comment
        action
        on_behalf_of
        on_behalf_of_user {
          id
          last_name
          first_name
          email
          project_users {
            subscription_vendor {
              name
            }
          }
          subscription_users {
            subscription {
              name
            }
          }
        }
        created_at
        created_by
        user {
          first_name
          email
          last_name
          id
          project_users {
            subscription_vendor {
              name
            }
          }
          subscription_users {
            subscription {
              name
            }
          }
        }
        assignee
        assignee_user {
          id
          email
          first_name
          last_name
          subscription_users {
            subscription {
              name
            }
          }
          project_users {
            subscription_vendor {
              name
            }
          }
        }
        submittal_revision
        submittal_response_id
        submittal_response {
          id
          key
          response
        }
        assignee_unregistered
        on_behalf_of_unregistered
      }
      submittal_date_block {
        ext_review_offset
        final_gc_review_offset
        gc_review_offset
        submittal_prep_offset
        float
        assigned_date
        ext_review_completed_date
        ext_review_submitted_date
        submittal_distributed_date
        trade_partner_submitted_date
        submittal_id
        actual_assigned_date
        actual_ext_review_completed_date
        actual_ext_review_submitted_date
        actual_submittal_distributed_date
        actual_trade_partner_submitted_date
        manual_entry
        projected_assigned_date
        projected_trade_partner_submitted_date
        projected_ext_review_submitted_date
        projected_ext_review_completed_date
        projected_submittal_distributed_date
      }
      date_block_submittals {
        name_milestone_1
        name_milestone_2
        name_milestone_3
        name_milestone_4
        name_milestone_5
        name_milestone_6
        name_milestone_7
        name_milestone_8
        name_milestone_9
        name_milestone_10
        actual_milestone_1
        actual_milestone_2
        actual_milestone_3
        actual_milestone_4
        actual_milestone_5
        actual_milestone_6
        actual_milestone_7
        actual_milestone_8
        actual_milestone_9
        actual_milestone_10
        name_offset_1
        name_offset_2
        name_offset_3
        name_offset_4
        name_offset_5
        name_offset_6
        name_offset_7
        name_offset_8
        name_offset_9
        name_offset_10
        offset_1
        offset_2
        offset_3
        offset_4
        offset_5
        offset_6
        offset_7
        offset_8
        offset_9
        offset_10
        planned_milestone_1
        planned_milestone_2
        planned_milestone_3
        planned_milestone_4
        planned_milestone_5
        planned_milestone_6
        planned_milestone_7
        planned_milestone_8
        planned_milestone_9
        planned_milestone_10
        projected_milestone_1
        projected_milestone_2
        projected_milestone_3
        projected_milestone_4
        projected_milestone_5
        projected_milestone_6
        projected_milestone_7
        projected_milestone_8
        projected_milestone_9
        projected_milestone_10
        projected_last_milestone_date
        projected_next_milestone_date
        
        manual_entry_date
        actual_float

        float
        id
        submittal_id
        project_id

        current_delay
        effective_float
        final_deadline_date
        planned_last_milestone_date
        planned_next_milestone_date
        today_date
        risk_assessment
        risk_level
        low_risk_threshold
        high_risk_threshold
        override_project_threshold
        workflow_status
        wf_override_reason
      }
      submittal_schedule_links {
        task_id
        submittal_id
        driving_task
        linked_to_end_date
        submittal {
          id
          submittal_id
        }
        gantt_task {
          id
          source_task_id
          text
          start_date
          end_date
        }
      }
      submittal_material_links {
        material_id
        driving_material
        material {
          material_id
          id
          name
          implicit
          actual_ROJ
          actual_fabrication_start_date
          actual_material_release_date
          actual_shipment_date
          material_schedule_links {
            driving_task
            linked_to_end_date
            gantt_task {
              id
              source_task_id
              text
              start_date
            }
          }
          material_date_block {
            ROJ_date
            fabrication_duration_offset
            fabrication_start_date
            lead_time_offset
            material_PO_date
            material_PO_fabrication_offset
            on_site_storage_offset
            shipment_date
            material_id
            projected_fabrication_start_date
            projected_material_PO_date
            projected_shipment_date
            projected_ROJ_date
            manual_entry
          }
          date_block_materials {
            name_milestone_1
            name_milestone_2
            name_milestone_3
            name_milestone_4
            name_milestone_5
            name_milestone_6
            name_milestone_7
            name_milestone_8
            name_milestone_9
            name_milestone_10
            actual_milestone_1
            actual_milestone_2
            actual_milestone_3
            actual_milestone_4
            actual_milestone_5
            actual_milestone_6
            actual_milestone_7
            actual_milestone_8
            actual_milestone_9
            actual_milestone_10
            name_offset_1
            name_offset_2
            name_offset_3
            name_offset_4
            name_offset_5
            name_offset_6
            name_offset_7
            name_offset_8
            name_offset_9
            name_offset_10
            offset_1
            offset_2
            offset_3
            offset_4
            offset_5
            offset_6
            offset_7
            offset_8
            offset_9
            offset_10
            planned_milestone_1
            planned_milestone_2
            planned_milestone_3
            planned_milestone_4
            planned_milestone_5
            planned_milestone_6
            planned_milestone_7
            planned_milestone_8
            planned_milestone_9
            planned_milestone_10
            manual_entry_date

            projected_milestone_1
            projected_milestone_2
            projected_milestone_3
            projected_milestone_4
            projected_milestone_5
            projected_milestone_6
            projected_milestone_7
            projected_milestone_8
            projected_milestone_9
            projected_milestone_10

            float
            id
            material_id
            final_deadline_date
            risk_assessment
            risk_level
            low_risk_threshold
            high_risk_threshold
            override_project_threshold
            workflow_template_id
            milestone_state
            workflow_status
            wf_override_reason
          }

          spec_section_name
          spec_section_no
          status
          attachments {
            file_name
            id
            blob_key
          }
        }
        material_sequence_id
        material_name
        submittal_id
        submittal_sequence_id
        title
        spec_no
        implicit
      }
      design_reviewer_unregistered
      assignee_unregistered
      design_reviewer_vendor_unregistered
      responsible_contractor_unregistered
      submitter_unregistered

      submittal_participation_from_integrations {
        submittal_participation_type {
          key
        }
        project_integration_user_participant {
          id
          first_name
          last_name
          project_integration_vendor {
            name
          }
        }
        project_integration_vendor_participant {
          id
          name
        }
      }
      spec_section {
        file_key
        specifications {
          latest_processed
        }
      }
      snippet_coordinates
      watchers
      priority
      submittal_number
    }
  }
`;

const SUBMITTAL_REVISIONS = `
  SubmittalSubscription($id: uuid!) {
    submittals_by_pk(id: $id) {
      id
      submittal_revisions
    }
  }
`;

const SUBMITTAL_ATTACHMENTS = `
  attachments($where: attachments_bool_exp!) {
    attachments(where: $where) {
      file_name
      file_size
      submittal_id
      material_id
      id
      blob_key
      version
      uploaded
      created_at
      file_type
      reference_id
      submittal_status_history {
        workflow_status
      }
      created_by_user {
        email
        first_name
        last_name
        project_users {
          subscription {
            name
          }
          subscription_vendor {
            name
          }
        }
      }
      versions {
        id
        blob_key
        version
        uploaded
        file_type
        file_name
        created_at
        submittal_id
        submittal_status_history {
          workflow_status
        }
        created_by_user {
          email
          first_name
          last_name
          project_users {
            subscription {
              name
            }
            subscription_vendor {
              name
            }
          }
        }
      }
      on_behalf_of_user_id
      on_behalf_of
      attachments_user {
        first_name
        id
        last_name
        email
      }
    }
  }
`;

const PROJECT_SPECS = `
  Specs(
    $where: spec_sections_bool_exp = {
      _or: [
        { status: { _eq: "INFERRED" } }
        { status: { _eq: "USER_CREATED" } }
      ]
    }
  ) {
    spec_sections(where: $where, order_by: { section_number: asc }) {
      title
      id
      section_number
      file_key
    }
  }
`;

const MATERIAL_BY_PK = `
  MaterialByPK($id: uuid!) {
    material_by_pk(id: $id) {
      actual_ROJ
      actual_fabrication_start_date
      actual_material_release_date
      actual_shipment_date
      assignee
      gc_representative
      gc_representative_user{
      first_name
      id
      last_name
      status_id
      } 
      id
      material_id
      name
      spec_section_name
      spec_section_no
      spec_section_id
      status
      part_number
      serial_number
      description
      warranty
      manufacturer
      quantity
      material_size
      tag_name
      created_on
      created_by_user {
        email
        first_name
        last_name
      }
      material_quantity_unit {
        measurement
        unit
        id
      }
      material_size_unit {
        id
        measurement
        unit
      }
      assignee_unregistered
      material_assignee {
        first_name
        email
        id
        last_name
        project_users {
          subscription_vendor {
            name
          }
          subscription {
            organization_subscriptions {
              organization {
                name
              }
            }
          }
        }
      }
      trade_partner
      materials_trade_partner {
        name
        id
      }
      material_schedule_links {
        driving_task
        linked_to_end_date
        gantt_task {
          id
          source_task_id
          text
          start_date
          end_date
        }
      }
      material_date_block {
        material_id
        ROJ_date
        fabrication_duration_offset
        fabrication_start_date
        lead_time_offset
        material_PO_date
        material_PO_fabrication_offset
        on_site_storage_offset
        shipment_date
        projected_fabrication_start_date
        projected_material_PO_date
        projected_shipment_date
        projected_ROJ_date
        manual_entry
      }
      date_block_materials {
        milestone_state
        name_milestone_1
        name_milestone_2
        name_milestone_3
        name_milestone_4
        name_milestone_5
        name_milestone_6
        name_milestone_7
        name_milestone_8
        name_milestone_9
        name_milestone_10
        actual_milestone_1
        actual_milestone_2
        actual_milestone_3
        actual_milestone_4
        actual_milestone_5
        actual_milestone_6
        actual_milestone_7
        actual_milestone_8
        actual_milestone_9
        actual_milestone_10
        name_offset_1
        name_offset_2
        name_offset_3
        name_offset_4
        name_offset_5
        name_offset_6
        name_offset_7
        name_offset_8
        name_offset_9
        name_offset_10
        offset_1
        offset_2
        offset_3
        offset_4
        offset_5
        offset_6
        offset_7
        offset_8
        offset_9
        offset_10
        planned_milestone_1
        planned_milestone_2
        planned_milestone_3
        planned_milestone_4
        planned_milestone_5
        planned_milestone_6
        planned_milestone_7
        planned_milestone_8
        planned_milestone_9
        planned_milestone_10
        projected_milestone_1
        projected_milestone_2
        projected_milestone_3
        projected_milestone_4
        projected_milestone_5
        projected_milestone_6
        projected_milestone_7
        projected_milestone_8
        projected_milestone_9
        projected_milestone_10
        projected_last_milestone_date
        projected_next_milestone_date
        current_delay

        manual_entry_date
        actual_float

        float
        id
        material_id

        effective_float
        final_deadline_date
        planned_last_milestone_date
        planned_next_milestone_date
        today_date
        risk_assessment
        risk_level
        low_risk_threshold
        high_risk_threshold
        override_project_threshold
        workflow_template_id
        workflow_status
        wf_override_reason
      }

      submittal_material_links {
        submittal_id
        submittal {
          submittal_id
          title
          id
          submittal_status_history_details(order_by: { created_at: desc }) {
            status
          }
          submittal_material_links {
            driving_material
          }
          submittal_schedule_links {
            linked_to_end_date
            driving_task
            gantt_task {
              id
              source_task_id
              text
            }
          }
          submittal_date_block {
            submittal_id
            actual_assigned_date
            actual_ext_review_completed_date
            actual_ext_review_submitted_date
            actual_submittal_distributed_date
            actual_trade_partner_submitted_date
            assigned_date
            ext_review_completed_date
            ext_review_offset
            ext_review_submitted_date
            final_gc_review_offset
            float
            gc_review_offset
            submittal_distributed_date
            submittal_prep_offset
            trade_partner_submitted_date
            manual_entry
            projected_assigned_date
            projected_trade_partner_submitted_date
            projected_ext_review_submitted_date
            projected_ext_review_completed_date
            projected_submittal_distributed_date
          }

          date_block_submittals {
            name_milestone_1
            name_milestone_2
            name_milestone_3
            name_milestone_4
            name_milestone_5
            name_milestone_6
            name_milestone_7
            name_milestone_8
            name_milestone_9
            name_milestone_10
            actual_milestone_1
            actual_milestone_2
            actual_milestone_3
            actual_milestone_4
            actual_milestone_5
            actual_milestone_6
            actual_milestone_7
            actual_milestone_8
            actual_milestone_9
            actual_milestone_10
            name_offset_1
            name_offset_2
            name_offset_3
            name_offset_4
            name_offset_5
            name_offset_6
            name_offset_7
            name_offset_8
            name_offset_9
            name_offset_10
            offset_1
            offset_2
            offset_3
            offset_4
            offset_5
            offset_6
            offset_7
            offset_8
            offset_9
            offset_10
            planned_milestone_1
            planned_milestone_2
            planned_milestone_3
            planned_milestone_4
            planned_milestone_5
            planned_milestone_6
            planned_milestone_7
            planned_milestone_8
            planned_milestone_9
            planned_milestone_10
            manual_entry_date

            projected_milestone_1
            projected_milestone_2
            projected_milestone_3
            projected_milestone_4
            projected_milestone_5
            projected_milestone_6
            projected_milestone_7
            projected_milestone_8
            projected_milestone_9
            projected_milestone_10

            float
            id
            submittal_id
            risk_assessment
            risk_level
            low_risk_threshold
            high_risk_threshold
            override_project_threshold
            workflow_status
            wf_override_reason
          }
          status
          workflow_status
          submittals_status {
            id
            status
          }
          submittal_attachments {
            version_of
            submittal_revision
            file_name
            id
          }
          revision
          spec_section_id
          spec_no
          spec_name
          type
        }

        material_id
        material_sequence_id
        material_name
        submittal_id
        submittal_sequence_id
        title
        spec_no
      }
    }
  }
`;

const SUBMITTAL_LIST_WF = `
  SubmittalList {
    submittal_list_wf_mode_func(order_by: { id: asc }) {
      actual_assigned_date
      actual_ext_review_completed_date
      actual_ext_review_submitted_date
      actual_submittal_distributed_date
      actual_trade_partner_submitted_date
      assignee_unregistered
      assignee_user_id
      attachment_count
      assignee_status
      gc_reviewer_status
      submitter_status
      design_reviewer_status
      created_by
      description
      design_reviewer_unregistered
      design_reviewer_user_id
      gc_reviewer_user_id
      history
      id
      materials
      planned_ext_review_completed_date
      planned_submittal_distributed_date
      planned_trade_partner_submitted_date
      project_id
      responsible_contractor_id
      responsible_contractor_name
      responsible_contractor_unregistered
      revision
      snippet_coordinates
      snippet_file_key
      spec_name
      spec_no
      state
      state_numeric
      submittal_sequence_id
      submitter_unregistered
      submitter_user_id
      tasks
      title
      type
      workflow_status
      spec_section_file_key
      snippet_coordinates
      next_planned_deadline
      next_action
      due_date
      risk_level
      priority
      watchers
      created_at
      float
      date_block_data
      submittal_number
    }
  }
`;

const SUBMITTAL_LIST_INTG = `
  SubmittalList {
    submittal_list_intg_mode_func(order_by: { id: asc }) {
      actual_assigned_date
      actual_ext_review_completed_date
      actual_ext_review_submitted_date
      actual_submittal_distributed_date
      actual_trade_partner_submitted_date
      assignee_unregistered
      assignee_user_id
      attachment_count
      assignee_status
      gc_reviewer_status
      submitter_status
      design_reviewer_status
      created_by
      description
      design_reviewer_unregistered
      design_reviewer_user_id
      gc_reviewer_user_id
      history
      id
      materials
      planned_ext_review_completed_date
      planned_submittal_distributed_date
      planned_trade_partner_submitted_date
      project_id
      responsible_contractor_id
      responsible_contractor_name
      responsible_contractor_unregistered
      revision
      snippet_coordinates
      snippet_file_key
      spec_name
      spec_no
      state
      state_numeric
      submittal_sequence_id
      submitter_unregistered
      submitter_user_id
      tasks
      title
      type
      workflow_status
      revision_date_blocks
      spec_section_file_key
      snippet_coordinates
      next_planned_deadline
      next_action
      due_date
      risk_level
      priority
      watchers
      created_at
      float
      date_block_data
      submittal_number
    }
  }
`;

const MATERIAL_LIST = `
ProjectMaterials {
  material_list_func(where: { implicit: { _neq: true } }, order_by: {id: asc}) {
    id
    material_id
    name
    spec_section_no
    spec_section_name
    description
    trade_partner
    trade_partner_name
    assignee
    assignee_unregistered
    assignee_first_name
    assignee_last_name
    assignee_organization_name
    assignee_vendor_name
    gc_representative
    gc_representative_first_name
    gc_representative_last_name
    actual_ROJ
    actual_fabrication_start_date
    actual_material_release_date
    actual_shipment_date
    planned_ROJ
    tasks
    submittals
    material_tag
    status
    delta
    quantity
    quantity_unit
    material_PO_fabrication_offset
    fabrication_duration_offset
    lead_time_offset
    on_site_storage_offset
    material_size
    material_size_unit
    manufacturer
    serial_number
    part_number
    warranty
    next_planned_deadline
    next_action
    due_date
    risk_level
    created_at
    date_block_template_name
    date_block_template_id
    float
    assignee_status
    project_engineer_status
    gc_representative_status
    date_block_data
  }
}
`;

const SUBSCRIPTION_INTEGRATIONS = `
Integrations {
  integrations {
    system
    id
    availability_status
  }
}
`;

const SUBSCRIPTION_INTG_CONFIGS = `
SubscriptionIntegrationConfigs {
  subscription_integration_configs {
    id
    config_id
    active
    subscription_id
    integration_config {
      id
      integration_config_properties {
        property_name
        property_value
        id
      }
    }
    system_integration_config {
      integration {
      system
      availability_status
      id
    }
      integration_config {
      id
      integration_config_properties {
        property_name
        property_value
        id
          }
    }
  }
  }
}
`;

const PROJECT_INTG_CONFIGS = `
ProjectIntegrationConfigs {
  project_integration_configs {
    id
    config_id
    active
    subscription_integration_config_id
    subscription_integration_config {
      config_id
      integration_config {
        integration_config_properties {
          property_name
          property_value
        }
      }
    }
    integration_config {
      id
      integration_config_properties {
        property_name
        property_value
        id
      }
    }
    system_integration_config {
      integration {
      system
      availability_status
      id
      }
      integration_config {
      id
      integration_config_properties {
        property_name
        property_value
        id
          }
    }
     
      subscription_integration_configs {
        integration_config {
          id
          integration_config_properties {
            id
            property_name
            property_value
          }
        }
      }
    }
    
    
  }
}
`;

const OAUTH_OUTPUT = `
GetOauthOutput($id: uuid!) {
  oauth_operations(where: {id: {_eq: $id}}) {
    operation_type
    source_type
    created_by_user {
      email
      first_name
      last_name
      id
    }
    oauth_status {
      status
      id
    }
    updated_at
    output
  }
}
`;

const PROJECT_DETAILS_PK_SETTINGS = `
  ProjectDetails($projectId: uuid!) {
    project_by_pk(id: $projectId) {
      mode
      spec_section
      name
    }
  }
`;

const SUBMITTAL_RISK_SUMMARY = `
  SubmittalRiskSummary {
    project_gc_cumulative_risk_report_summary_func {
      item_type
      risk_level
      count
    }
  }
`;

const SUBMITTAL_RISK_REPORT_WF = `
SubmittalRiskReportWF($where: submittal_cumulative_risk_report_wf_mode_view_interface_bool_exp) {
  submittal_cumulative_risk_report_wf_mode_func(where: $where, order_by: {submittal_id: asc}) {
    id
    submittal_id
    assignee_user_id
    assignee_unregistered
    assignee_status
    date_block_submittals
    project_id
    responsible_contractor_id
    responsible_contractor_name
    spec_name
    spec_no
    status
    submittal_material_links
    submittal_schedule_links
    workflow_status
    title
    type
    history
    submitter_user_id
    gc_reviewer_user_id
    design_reviewer_user_id
    design_reviewer_unregistered
    submitter_unregistered
    risk_level
  }
}
`;
const SUBMITTAL_RISK_REPORT_INTG = `
SubmittalRiskReportWF($where: submittal_cumulative_risk_report_intg_mode_view_interface_bool_exp) {
  submittal_cumulative_risk_report_intg_mode_func(where: $where, order_by: {submittal_id: asc}) {
    id
    submittal_id
    assignee_user_id
    assignee_status
    date_block_submittals
    project_id
    responsible_contractor_id
    responsible_contractor_name
    spec_name
    spec_no
    submittal_material_links
    submittal_schedule_links
    workflow_status
    title
    type
    actual_assigned_date
    actual_ext_review_completed_date
    actual_ext_review_submitted_date
    actual_submittal_distributed_date
    actual_trade_partner_submitted_date
    planned_ext_review_completed_date
    planned_submittal_distributed_date
    planned_trade_partner_submitted_date
    submitter_user_id
    gc_reviewer_user_id
    design_reviewer_user_id
    risk_level
  }
}
`;

const MATERIAL_RISK_REPORT = `
  MaterialRiskReport($where: material_cumulative_risk_report_view_interface_bool_exp) {
    material_cumulative_risk_report_func(where: $where, order_by: {material_id: asc}) {
      assignee
      assignee_first_name
      assignee_last_name
      assignee_status
      date_block_materials
      id
      material_id
      material_schedule_links
      material_submitttal_links
      material_tag
      name
      project_id
      risk_level
      spec_section_name
      spec_section_no
      status
      trade_partner_name
      gc_representative
      gc_representative_first_name
      gc_representative_last_name
      gc_representative_status
    }
  }
`;

const COLUMN_HEADERS = `
  ColumnHeaders {
    project_feature_configurations {
      feature_id
      field_mapping
      float
      project_id
    }
  }
`;

const SAVED_COLUMN_HEADERS = `
  SavedColumnHeaders($where: project_users_bool_exp) {
    project_users(where:$where) {
      project_id
      submittal_column_config
      material_column_config
    }
  }
`;

const DATEBLOCK_ACTIONS = `
  DateBlockActions($where: project_feature_configurations_bool_exp = {}) {
    project_feature_configurations (where: $where){
      feature_id
      date_block_action
    }
  }
`;
const GET_SPECIFICATIONS = `
GetSpecifications {
  specifications(where: {status: {_in: ["PROCESSED", "PROCESSING"]}, version_of: {_is_null: true}}) {
    id
    title
    status
    version_of
    version_name
    version_date
    created_at
    created_by_user {
      email
      first_name
      last_name
    }
    spec_text_analysis_stat {
      spec_file_key
    }
    child_specifications(where: {status: {_in: ["PROCESSED", "PROCESSING"]}}) {
      id
      title
      status
      version_of
      version_name
      version_date
      created_at
      created_by_user {
        email
        first_name
        last_name
      }
      parent_specification {
        id
      }
      spec_text_analysis_stat {
        spec_file_key
      }
    }
  }
}
`;

const GET_SUBMITTAL_INSIGHTS = `
GetSubmittalInsights($where: submittal_insights_view_bool_exp) {
  submittal_insights_view(where: $where) {
    title
    project_name
    spec_section_name
    spec_section_number
    responsible_contractor_name
    submittal_prep_time
    design_review_time
    total_workflow_time
    total_revisions
    submittal_type
  }
  }
`;

const PROJECT_INSIGHTS_CONFIG = `
  ProjectInsightsConfig {
    project_feature_configurations {
      feature_id
      insights_enabled
    }
  }
`;

const GET_COMMENTS = `
GetComments($where: comment_bool_exp) {
  comment(where: $where, order_by: {created_at: desc}) {
    id
    description
    attachments {
      id
      file_name
      file_type
      file_size
      blob_key
    }
    workflow_step
    workflow_template_id
    created_at
    created_by
    created_by_user {
      email
      first_name
      last_name
      project_users {
        subscription {
          name
        }
      }
    }
  }
}
`;

const GET_PROJECT_WORKFLOW_TEMPLATE_NAMES = `GetProjectWorkflowTemplates($featureId: Int!) {
  project_workflow_templates(where: {feature_id: {_eq: $featureId}, disabled: {_eq: false}}) {
    name
    id
    default
  }
}`;

const GET_MDB_TEMPLATES = `
  GetMDBTemplates {
    project_workflow_templates(where: {feature_id: {_eq: 2}}) {
      id
      name
      default
      disabled
      feature_id
      additional_offset
      float
      project_template_milestones(order_by: {sequence_no: asc}) {
        id
        sequence_no
        name
        milestone_action
        next_offset {
          name
          id
          duration
        }
      }
      updated_by
      updated_by_user {
        first_name
        email
        last_name
        id
      }
      updated_at
      created_by_user {
        email
        first_name
        last_name
      }
      created_at
      date_blocks_aggregate(where: {workflow_status: {_eq: "IN_PROGRESS"}}) {
        aggregate {
          count
        }
      }
    }
  }
`;

const GET_PROJECT_DB_TEMPLATES = `
  GetProjectDBTemplates($featureId: Int!) {
    project_workflow_templates(where: {feature_id: {_eq: $featureId}}) {
      id
      name
      default
      disabled
      feature_id
      additional_offset
      float
      project_template_milestones(order_by: {sequence_no: asc}) {
        id
        sequence_no
        name
        milestone_action
        next_offset {
          name
          id
          duration
        }
      }
      updated_by
      updated_by_user {
        first_name
        email
        last_name
        id
      }
      updated_at
      created_by_user {
        email
        first_name
        last_name
      }
      created_at
      date_blocks_aggregate(where: {workflow_status: {_eq: "IN_PROGRESS"}}) {
        aggregate {
          count
        }
      }
    }
  }
`;

const GET_MDB_SUBSCRIPTION_TEMPLATES = `
  GetMDBTemplates {
    subscription_workflow_templates(where: {feature_id: {_eq: 2}}) {
      id
      name
      default
      disabled
      feature_id
      additional_offset
      float
      updated_by
      updated_by_user {
        first_name
        email
        last_name
        id
      }
      updated_at  
      created_by_user {
        email
        first_name
        last_name
      }
      created_at  
      subscription_template_milestones(order_by: {sequence_no: asc}) {
         id
          sequence_no
          name
          milestone_action
          next_offset {
            name
            id
            duration
          }        
      }
    }
  }
`;

const HISTORY = `
  HistoryQuery($where: History_bool_exp!) {
    History(where: $where, order_by: { created_at: desc }) {
      id
      field
      label
      old_value
      new_value
      user_created {
        email
        first_name
        last_name
      }
      created_at
      project_id
      new_subscription_vendor {
        name
      }
      old_subscription_vendor {
        name
      }
      user_old {
        email
        first_name
        last_name
      }
      user_new {
        email
        first_name
        last_name
      }
    }
  }
`;

const SYSTEM_INTEGRATION_CONFIGS = `
SystemIntegrationConfig {
  system_integration_configs {
    integration {
      system
    }
    integration_config {
      integration_config_properties {
        property_name
        property_value
      }
    }
}
}`;

const INTEGRATION_IMPORT_LOGS = `
IntegrationImportLog {
  import_log(limit: 1, order_by: {created_at: desc}, where: {_and: {status: {_in: ["PROCESSED", "PROCESSING", "FAILED"]}, import_type: {_in: [ACC_IMPORT, BIM360_IMPORT, PROCORE_IMPORT]}}}) {
    metadata
    status
    id
  }
}`;

const USER_CONFIG_VIEWS = `getAllUserView($feature_id: Int!) {
  project_feature_user_view(where: {feature_id: {_eq: $feature_id}}) {
    id
    name
    column_config
  }
}`;

const GET_DB_SUBSCRIPTION_TEMPLATES = `
  GetDBTemplates($feature_id: Int!) {
    subscription_workflow_templates(where: {feature_id: {_eq: $feature_id}}) {
      id
      name
      default
      disabled
      feature_id
      additional_offset
      float
      updated_by
      updated_by_user {
        first_name
        email
        last_name
        id
      }
      updated_at  
      created_by_user {
        email
        first_name
        last_name
      }
      created_at  
      subscription_template_milestones(order_by: {sequence_no: asc}) {
         id
          sequence_no
          name
          milestone_action
          next_offset {
            name
            id
            duration
          }        
      }
    }
  }
`;

const GET_PROJECT_FEATURE_CONFIGURATION = `
  GetProjectFeatureConfiguration {
    project_feature_configurations {
      feature_id
      high_risk_threshold
      low_risk_threshold
    }
  }
`;

export {
  SUBMITTAL_BY_PK,
  SUBMITTAL_REVISIONS,
  SUBMITTAL_ATTACHMENTS,
  PROJECT_SPECS,
  MATERIAL_BY_PK,
  SUBMITTAL_LIST_WF,
  MATERIAL_LIST,
  SUBSCRIPTION_INTG_CONFIGS,
  SUBSCRIPTION_INTEGRATIONS,
  OAUTH_OUTPUT,
  PROJECT_INTG_CONFIGS,
  PROJECT_DETAILS_PK_SETTINGS,
  SUBMITTAL_RISK_SUMMARY,
  SUBMITTAL_RISK_REPORT_WF,
  SUBMITTAL_RISK_REPORT_INTG,
  MATERIAL_RISK_REPORT,
  COLUMN_HEADERS,
  SUBMITTAL_LIST_INTG,
  SAVED_COLUMN_HEADERS,
  GET_SPECIFICATIONS,
  GET_SUBMITTAL_INSIGHTS,
  PROJECT_INSIGHTS_CONFIG,
  GET_COMMENTS,
  DATEBLOCK_ACTIONS,
  GET_MDB_TEMPLATES,
  GET_PROJECT_DB_TEMPLATES,
  GET_PROJECT_WORKFLOW_TEMPLATE_NAMES,
  GET_MDB_SUBSCRIPTION_TEMPLATES,
  HISTORY,
  SYSTEM_INTEGRATION_CONFIGS,
  INTEGRATION_IMPORT_LOGS,
  USER_CONFIG_VIEWS,
  GET_DB_SUBSCRIPTION_TEMPLATES,
  GET_PROJECT_FEATURE_CONFIGURATION
};
