import DateInputIcon from "components/svg-icons/date-icon";
import { VRules } from "components/workflow-template-editor/validation-rules";
import { FeatureTypes } from "entity-app/constants";
import EditableInput from "./editable-input";

function Milestone({
  milestone,
  index,
  allMilestones,
  onMilestoneValueChange,
  disable = false,
  floatState,
  featureType
}: {
  milestone: any;
  index: number;
  allMilestones: any;
  disable: boolean;
  onMilestoneValueChange: (data: any) => void;
  floatState: { setFloat: (value: any) => void; float: any };
  featureType: FeatureTypes;
}) {
  const isFirst = index === 0;
  const isLast = index === allMilestones.length - 1;

  const floatFieldLabels: Partial<Record<FeatureTypes, string>> = {
    [FeatureTypes.SUBMITTALS]: "Submittal Float",
    [FeatureTypes.MATERIALS]: "Material Float",
    [FeatureTypes.BID_PACKAGE]: "Float"
  };

  const offsetDiv = milestone.offsetName && (
    <div className="w-80 h-10 shrink-0 flex-col space-y-2 justify-end">
      <div className="w-full flex justify-end">
        <EditableInput
          disable={disable}
          fieldLabel={milestone.offsetName}
          fieldValue={milestone.value}
          rules={VRules.Duration}
          onSubmit={(value: any) => {
            const data: any = { milestone_index: index, value };
            onMilestoneValueChange(data);
          }}
        />
      </div>
    </div>
  );

  const lastOffsetDiv = isLast && (
    <div className="w-80 h-10 shrink-0 flex-col space-y-1">
      <div className="w-full flex justify-end">
        <EditableInput
          disable={disable}
          fieldLabel={floatFieldLabels[featureType]}
          fieldValue={floatState.float}
          rules={VRules.Duration}
          onSubmit={(value: any) => {
            floatState.setFloat(value);
          }}
        />
      </div>
    </div>
  );

  const horizontalLineInitialPaddingDiv = isFirst ? (
    <div className="bl-left-emplty" />
  ) : (
    <div className="bl-line-left " />
  );

  const horizontalLineRectangleDiv = (
    <div className="flex items-center">
      <div className="bl-line" /> <div className="bl-rect" />
    </div>
  );

  const horizontalLineLastRectangleDiv = milestone.offsetName ? (
    <div className="flex items-center">
      <div className="bl-line" />
      <div className="bl-rect bg-gray-300" />
      <div className="relative w-48">
        <div className="bl-line !w-48" />
        <div className="absolute left-20 -top-6">
          <DateInputIcon />
        </div>
      </div>
      <div className="bl-rect flex justify-end items-center" />
      <div className="bl-left-emplty" />
    </div>
  ) : (
    <div className="flex items-center">
      <div className="bl-line" />
      <div className="bl-rect bg-gray-300" />
      <div className="relative">
        <div className="bl-line" />
        <div className="absolute left-5 -top-6">
          <DateInputIcon />
        </div>
      </div>

      <div className="bl-left-emplty" />
    </div>
  );

  const horizontalLineDiv = (
    <div className="flex w-full items-center">
      {horizontalLineInitialPaddingDiv}
      <div className="bl-circle" />
      {isLast ? horizontalLineLastRectangleDiv : horizontalLineRectangleDiv}
    </div>
  );

  const milestoneHeaderDiv = (
    <div
      className="p-2 h-12 border-solid border truncate leading-8"
      style={{ width: "228px" }}
    >
      {milestone.name}
    </div>
  );

  return (
    <div className="pb-5 shrink-0 flex-col relative">
      <div className="h-28">
        <div className="w-80 absolute left-10 top-4 flex-col space-y-5">
          {isLast ? (
            <div className="flex">
              {lastOffsetDiv}
              <div className="-ml-10">{offsetDiv}</div>
            </div>
          ) : (
            offsetDiv
          )}
          {horizontalLineDiv}
        </div>
      </div>

      <div className="w-full flex space-x-3 items-center px-1 mt-8">
        <div className="w-full flex-col ml-10">{milestoneHeaderDiv}</div>
      </div>
    </div>
  );
}

export default Milestone;
