import { Select } from "antd";
import GCReviewersDropdown from "components/gc-reveiewer-dropdown";
import { ProjectContext } from "context/ProjectProvider";
import { useCIQQuery } from "hooks/ciq-gql-hooks";
import { useProjectParticipants } from "hooks/project-participants";
import {
  forwardRef,
  useState,
  useImperativeHandle,
  useMemo,
  useContext
} from "react";
import { GET_PROJECT_WORKFLOW_TEMPLATE_NAMES } from "services/graphQL/ciq-queries";

function WorkflowTemplateDropdown(props: any) {
  const { onChange, value, featureTypeId } = props;

  const { gqlClientForProject } = useContext(ProjectContext);
  const { data: projectWorkflowTemplates, loading } = useCIQQuery<{
    project_workflow_templates: Array<any>;
  }>(GET_PROJECT_WORKFLOW_TEMPLATE_NAMES, {
    client: gqlClientForProject,
    variables: { featureId: featureTypeId }
  });

  const options = useMemo(() => {
    const tooltip = "tooltip";
    if (
      projectWorkflowTemplates &&
      projectWorkflowTemplates.project_workflow_templates
    ) {
      const templates =
        projectWorkflowTemplates.project_workflow_templates.filter(
          (template: any) => !template.disabled || template.id === value
        );

      return templates.map((t) => ({
        label: t.name,
        value: t.id,
        tooltip
      }));
    }
    return [] as any[];
  }, [projectWorkflowTemplates, value]);

  return (
    <Select
      className="w-full"
      options={options}
      value={value}
      onChange={onChange}
      loading={loading}
      placeholder="Select a template"
    />
  );
}

function ResponsibleContractorDropdown(props: any) {
  const { onChange, value } = props;
  const { projectParticipants } = useProjectParticipants();
  const subContractors = projectParticipants?.responsibleContractors;

  return (
    <Select
      className="w-full"
      autoFocus
      value={value}
      loading={!projectParticipants?.dataLoaded}
      options={
        subContractors
          ? subContractors.map((company: any) => ({
              label: company.subscription_vendor.name,
              value: company.vendor_id
            }))
          : []
      }
      onChange={onChange}
    />
  );
}

const EntityDropdownCellEditor = forwardRef((props: any, ref) => {
  const { stopEditing, user } = props;
  const [value, setValue] = useState(props.value);

  useImperativeHandle(ref, () => {
    return {
      getValue() {
        return value;
      }
    };
  });

  const options = useMemo(
    () => ({
      autoFocus: true,
      value,
      onChange: (val: string, option: any) => {
        const res = {} as any;
        res[user.id] = option.value;
        if (option.firstName) {
          res[user.firstName] = option.firstName;
          res[user.lastName] = option.lastName;
        } else {
          res[user.firstName] = option.label;
          res[user.lastName] = undefined;
        }
        if (user.statusId) {
          res[user.statusId] = undefined;
        }

        setValue(res);

        setTimeout(() => {
          stopEditing();
        }, 200);
      }
    }),
    [stopEditing, user, value]
  );

  const dropDrop = useMemo(() => {
    switch (user.dropdownType) {
      case "RC_dropdown":
        return <ResponsibleContractorDropdown {...options} />;
      case "Template_dropdown":
        return (
          <WorkflowTemplateDropdown
            {...options}
            featureTypeId={user.featureTypeId}
          />
        );
      default:
        return <GCReviewersDropdown {...options} />;
    }
  }, [options, user.dropdownType, user.featureTypeId]);

  return <div className="h-full flex items-end">{dropDrop}</div>;
});

export default EntityDropdownCellEditor;
