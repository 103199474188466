import { DeleteOutlined } from "@ant-design/icons";
import { message, Modal } from "antd";
import "./delete-project.scss";
import { useCIQMutation } from "hooks/ciq-gql-hooks";
import { MUTATION_DELETE_PROJECT } from "services/graphQL/mutations";
import { useState } from "react";

function DeleteProjectComponent(props: any) {
  const { data, onProjectDeleted } = props;
  const [open, setOpen] = useState(false);
  const [deleteProject] = useCIQMutation(MUTATION_DELETE_PROJECT);
  const [isHovered, setHovered] = useState(false);

  const onConfirm = async () => {
    if (data.id) {
      const variables = {
        projectIds: [data.id]
      };
      const response = await deleteProject({
        variables
      });

      if (response?.success) {
        setOpen(false);
        message.success("Project deleted successfully.");
        onProjectDeleted();
      }
    }
  };

  const content = (
    <p className=" text-[#000000E0] font-medium text-base text-left">
      Are you sure you want to permanently delete the selected Project “[
      {data?.name ?? ""}]” ?
    </p>
  );

  return (
    <div className="flex w-full justify-center">
      <button
        type="button"
        className="bg-transparent border-none"
        onClick={() => {
          Modal.confirm({
            open,
            className: "delete-project",
            content,
            onOk: () => onConfirm(),
            okText: "Delete",
            centered: true
          });
        }}
      >
        <DeleteOutlined
          onMouseEnter={() => setHovered(true)}
          onMouseLeave={() => setHovered(false)}
          className="cursor-pointer px-1"
          style={{ color: isHovered ? "#EE4B2B" : "#3B3B3BCC" }}
        />
      </button>
    </div>
  );
}

export default DeleteProjectComponent;
