import { QUERY_PROJECT_DETAILS_PK_SETTINGS } from "services/graphQL/queries";
import { useCallback, useEffect } from "react";
import { PROJECT_INTG_CONFIGS } from "services/graphQL/ciq-queries";
import { getGQL, isProjectInIntegrationMode } from "utils/utils";
import { TAuditChangeLog } from "change-events/change-event-polling";
import { useCIQQuery } from "./ciq-gql-hooks";
import { useGetProjectCalendarHolidaysAndWeekends } from "./project-cal-holidays";
import { useSubmittalMaterialLogsHeaders } from "./column-headers";
import { useGetACCImportLogData } from "./project-import-log";

type TAuditChangeLogs = Array<TAuditChangeLog>;

export const useProjectGlobalDataSource = (
  eventLogs: TAuditChangeLogs,
  gqlClientForProject: any,
  additionalParams: any
) => {
  const { projectId } = additionalParams;

  const { data: projectDetails, refetch: refetchProjectDetails } = useCIQQuery(
    QUERY_PROJECT_DETAILS_PK_SETTINGS,
    {
      client: gqlClientForProject,
      variables: { projectId },
      skip: !gqlClientForProject
    }
  );

  const { calendarOffs, refetch: refetchCalendarData } =
    useGetProjectCalendarHolidaysAndWeekends(projectId, gqlClientForProject);

  const { columnHeaders, refetch: refetchHeaders } =
    useSubmittalMaterialLogsHeaders(gqlClientForProject);

  const { query: projectIntgQueryDef } = getGQL(PROJECT_INTG_CONFIGS);

  const { data: projectIntgConfigs } = useCIQQuery(projectIntgQueryDef, {
    client: gqlClientForProject,
    skip: !isProjectInIntegrationMode(projectDetails?.project_by_pk.mode)
  });

  const { lastImportStatus, refetchIntegrationImportLogs } =
    useGetACCImportLogData(gqlClientForProject);

  const handleUpdatesForProjectDetails = useCallback(
    (logs: TAuditChangeLogs) => {
      const projectChangeLog = logs.find(
        (event: TAuditChangeLog) => event.data_source === "project"
      );
      if (projectChangeLog && projectChangeLog.project_id === projectId) {
        refetchProjectDetails();
      }
    },
    [projectId, refetchProjectDetails]
  );

  const handleUpdatesForCalendar = useCallback(
    (logs: TAuditChangeLogs) => {
      const calendarEventSources = [
        "calendar_shift_mapping",
        "gantt_calendar",
        "calendar_holiday_list"
      ];
      const calendarChangeLog = logs.find((event: TAuditChangeLog) =>
        calendarEventSources.includes(event.data_source)
      );
      if (calendarChangeLog && calendarChangeLog.project_id === projectId) {
        refetchCalendarData();
      }
    },
    [projectId, refetchCalendarData]
  );

  const handleUpdatesForHeaders = useCallback(
    (logs: TAuditChangeLogs) => {
      const headersChangeLog = logs.find(
        (event: TAuditChangeLog) =>
          event.data_source === "project_feature_configurations"
      );
      if (headersChangeLog && headersChangeLog.project_id === projectId) {
        refetchHeaders();
      }
    },
    [projectId, refetchHeaders]
  );

  const handleACCImportLog = useCallback(
    (logs: TAuditChangeLogs) => {
      const accImportLog = logs.find(
        (event: TAuditChangeLog) => event.data_source === "import_log"
      );
      if (accImportLog && accImportLog.project_id === projectId) {
        refetchIntegrationImportLogs();
      }
    },
    [projectId, refetchIntegrationImportLogs]
  );

  useEffect(() => {
    if (!eventLogs || !eventLogs.length) return;
    handleUpdatesForProjectDetails(eventLogs);
    handleUpdatesForCalendar(eventLogs);
    handleUpdatesForHeaders(eventLogs);
    handleACCImportLog(eventLogs);
  }, [
    eventLogs,
    handleUpdatesForCalendar,
    handleUpdatesForHeaders,
    handleUpdatesForProjectDetails,
    handleACCImportLog
  ]);

  return {
    projectDetails,
    calendarOffs,
    columnHeaders,
    projectIntgConfigs,
    lastImportStatus
  };
};
