import { gql } from "@apollo/client";
import { CellEditRequestEvent } from "ag-grid-community";
import { message } from "antd";
import {
  CompanyParticipationType,
  UserParticipationType
} from "constants/index";
import { ProjectContext } from "context/ProjectProvider";
import {
  MUTATION_UPDATE_FEATURE_USER_PARTICIPANT,
  UPDATE_FEATURE_BID_PACKAGE_BY_PK,
  UPDATE_FEATURE_COMPANY_PARTICIPANT,
  UPDATE_FEATURE_INSTANCE_BY_PK,
  UPDATE_FEATURE_INSTANCE_SPEC_SECTION,
  UPDATE_FEATURE_INSTANCE_WORKFLOW_TEMPLATE,
  UPDATE_FEATURE_WORKFLOW_INSTANCE
} from "entity-app/graphQL/ciq-feature-mutations";
import { userEntityCellRenderer } from "entity-app/shared-components/log-render-components/cell-renders";
import { useCIQMutation } from "hooks/ciq-gql-hooks";
import { useContext } from "react";

export default function useFeatureLogInlineEdit({
  refetchListData
}: {
  refetchListData: () => void;
}) {
  const { gqlClientForProject } = useContext(ProjectContext);

  const [mutationUpdateFeatureInstanceFields] = useCIQMutation(
    gql(UPDATE_FEATURE_INSTANCE_BY_PK),
    {
      client: gqlClientForProject
    }
  );

  const [mutationUpdateFeatureWorkflowInstance] = useCIQMutation(
    gql(UPDATE_FEATURE_WORKFLOW_INSTANCE),
    {
      client: gqlClientForProject
    }
  );

  const [mutationUpdateFeatureBidPackageFields] = useCIQMutation(
    gql(UPDATE_FEATURE_BID_PACKAGE_BY_PK),
    {
      client: gqlClientForProject
    }
  );

  const [mutationUpdateFeatureUserParticipant] = useCIQMutation(
    gql(MUTATION_UPDATE_FEATURE_USER_PARTICIPANT),
    {
      client: gqlClientForProject
    }
  );

  const [mutationUpdateFeatureCompanyParticipant] = useCIQMutation(
    gql(UPDATE_FEATURE_COMPANY_PARTICIPANT),
    {
      client: gqlClientForProject
    }
  );

  const [mutationUpdateFeatureInstanceWorkflowTemplate] = useCIQMutation(
    gql(UPDATE_FEATURE_INSTANCE_WORKFLOW_TEMPLATE),
    {
      client: gqlClientForProject
    }
  );

  const [mutationUpdateFeatureSpecSection] = useCIQMutation(
    gql(UPDATE_FEATURE_INSTANCE_SPEC_SECTION),
    {
      client: gqlClientForProject
    }
  );

  const showMessage = async (updateResponse: any, revertDataFunc: Function) => {
    if (updateResponse.success) {
      message.success("Updated successfully.");
      setTimeout(refetchListData, 1000);
    } else {
      message.error("Failed to update.");
      revertDataFunc();
    }
  };

  const updateFeatureCompanyParticipant = async ({
    featureInstanceId,
    companyParticipationType,
    vendorIds
  }: {
    featureInstanceId: string;
    companyParticipationType: CompanyParticipationType;
    vendorIds: Array<string>;
  }) => {
    const objects = vendorIds.map((id) => ({
      instance_id: featureInstanceId,
      company_participation_type_id: companyParticipationType,
      vendor_id: id
    }));
    const variables: any = {
      instance_id: featureInstanceId,
      company_participation_type_id: companyParticipationType,
      objects
    };
    return mutationUpdateFeatureCompanyParticipant({
      variables
    });
  };

  const updateFeatureUserParticipant = async ({
    featureInstanceId,
    userParticipationType,
    userId
  }: {
    featureInstanceId: string;
    userParticipationType: UserParticipationType;
    userId: string;
  }) => {
    const variables: any = {
      featureInstanceId,
      user_participation_type_id: userParticipationType,
      object: {
        instance_id: featureInstanceId,
        user_id: userId,
        user_participation_type_id: userParticipationType
      }
    };
    return mutationUpdateFeatureUserParticipant({
      variables
    });
  };

  const onCellEditRequest = async (event: CellEditRequestEvent<any, any>) => {
    if (event.newValue === "") return;
    const colId = event.colDef.colId!;
    const existingRowData = { ...event.data };

    const revertDataFunc = () => {
      event.node.setData({ ...existingRowData });
    };

    let changedData: any = {};
    if (event.colDef.cellRenderer === userEntityCellRenderer) {
      changedData = event.newValue;
    } else {
      changedData[colId] = event.newValue;
    }

    event.node.setData({ ...existingRowData, ...changedData });

    switch (colId) {
      case "number":
      case "title":
        {
          const variables: any = {
            featureId: event.data.id,
            set: { [event.colDef.field!]: event.newValue }
          };
          const updateResponse = await mutationUpdateFeatureInstanceFields({
            variables
          });

          showMessage(updateResponse, revertDataFunc);
        }
        break;
      case "wave":
      case "no_of_bidders":
      case "estimated_value":
      case "awarded_value":
        {
          const variables: any = {
            featureId: event.data.id,
            set: { [event.colDef.field!]: event.newValue }
          };
          const updateResponse = await mutationUpdateFeatureBidPackageFields({
            variables
          });

          showMessage(updateResponse, revertDataFunc);
        }
        break;

      case "float":
        {
          const variables: any = {
            id: event.data.feature_workflow_instance_id,
            set: {
              [event.colDef.field!]: event.newValue,
              template_value_overidden: true
            }
          };
          const updateResponse = await mutationUpdateFeatureWorkflowInstance({
            variables
          });

          showMessage(updateResponse, revertDataFunc);
        }
        break;
      case "bidding_subcontractors":
        {
          const vendorIds = event.newValue.map((x: any) => x.id);
          const updateResponse = await updateFeatureCompanyParticipant({
            featureInstanceId: event.data.id,
            companyParticipationType:
              CompanyParticipationType.BIDDING_SUB_CONTRACTOR,
            vendorIds
          });

          showMessage(updateResponse, revertDataFunc);
        }
        break;
      case "bid_scope":
        {
          const objects = event.newValue.map((x: any) => ({
            bid_scope: true,
            feature_instance_id: event.data.id,
            spec_section_id: x.id
          }));

          const variables: any = {
            instance_id: event.data.id,
            objects
          };
          const updateResponse = await mutationUpdateFeatureSpecSection({
            variables
          });

          showMessage(updateResponse, revertDataFunc);
        }
        break;
      case "preconstruction_lead_id":
        {
          const updateResponse = await updateFeatureUserParticipant({
            featureInstanceId: event.data.id,
            userId: event.newValue.preconstruction_lead_id,
            userParticipationType: UserParticipationType.PRECONSTRUCTION_LEAD
          });

          showMessage(updateResponse, revertDataFunc);
        }
        break;
      case "gc_representative_id":
        {
          const updateResponse = await updateFeatureUserParticipant({
            featureInstanceId: event.data.id,
            userId: event.newValue.gc_representative_id,
            userParticipationType: UserParticipationType.GC_REPRESENTATIVE
          });

          showMessage(updateResponse, revertDataFunc);
        }
        break;
      case "operations_lead_id":
        {
          const updateResponse = await updateFeatureUserParticipant({
            featureInstanceId: event.data.id,
            userId: event.newValue.operations_lead_id,
            userParticipationType: UserParticipationType.OPERATIONS_LEAD
          });

          showMessage(updateResponse, revertDataFunc);
        }
        break;

      case "awarded_subcontractor_id":
        {
          const updateResponse = await updateFeatureCompanyParticipant({
            featureInstanceId: event.data.id,
            companyParticipationType:
              CompanyParticipationType.AWARDED_SUB_CONTRACTOR,
            vendorIds: [event.newValue.awarded_subcontractor_id]
          });
          showMessage(updateResponse, revertDataFunc);
        }
        break;
      case "bid_package_template_id":
        {
          const variables: any = {
            featureInstanceId: event.data.id,
            workflowTemplateId: event.newValue.bid_package_template_id
          };

          const updateResponse =
            await mutationUpdateFeatureInstanceWorkflowTemplate({
              variables
            });

          showMessage(updateResponse, revertDataFunc);
        }
        break;

      default:
        break;
    }
  };

  return { onCellEditRequest };
}
