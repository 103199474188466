import React, { useReducer, useMemo, useEffect, useContext } from "react";
import {
  PROJECT_INTG_CONFIGS,
  SUBSCRIPTION_INTEGRATIONS,
  SUBSCRIPTION_INTG_CONFIGS,
  SYSTEM_INTEGRATION_CONFIGS
} from "services/graphQL/ciq-queries";

import { useCIQQuerySubscription } from "hooks/ciq-gql-hooks";

import {
  IntegrationType,
  simplifySubscriptionIntegrationConfigs,
  simplifyProjectIntegrationConfigs,
  simplifySystemIntegrationConfigs
} from "pages/subscription-settings/integrations-tab-new/utils";

import { ProjectContext, TProjectContext } from "context/ProjectProvider";
import { invokeACCProjectSyncJob } from "services/integration-service";
import {
  ProjectIntegrationsTabSharedContext,
  ProjectIntegrationsTabStateReducer,
  projectInitialState
} from "./project-integrations-tab-context";
import ProjectIntegrationsList from "./project-integrations-list";
import ProjectProcoreInitialIntegration from "./procore/project-initial-integration";
import ProjectProcoreIntegrationDetails from "./procore/project-integration-details";
import ProjectAutodeskInitialIntegration from "./autodesk/project-initial-integration";
import ProjectAutodeskIntegrationDetails from "./autodesk/project-integration-details";

function ProjectIntegrationsTab() {
  const { gqlClientForProject, tokenRetrievalState }: TProjectContext =
    useContext(ProjectContext);

  const [sharedState, dispatch] = useReducer(
    ProjectIntegrationsTabStateReducer,
    projectInitialState
  );

  const providerValue: any = useMemo(
    () => ({
      sharedState,
      dispatch
    }),
    [sharedState]
  );

  // console.log("Project IntegrationsTab state ", sharedState);

  const { data: dataSystemIntgConfigs } = useCIQQuerySubscription(
    SYSTEM_INTEGRATION_CONFIGS,
    { client: gqlClientForProject }
  );

  const { data: dataAvailableIntegrations } = useCIQQuerySubscription(
    SUBSCRIPTION_INTEGRATIONS,
    { client: gqlClientForProject }
  );

  const { data: dataSubscriptionIntgConfigs } = useCIQQuerySubscription(
    SUBSCRIPTION_INTG_CONFIGS,
    { client: gqlClientForProject }
  );

  const { data: dataProjectIntgConfigs } = useCIQQuerySubscription(
    PROJECT_INTG_CONFIGS,
    {
      client: gqlClientForProject
    }
  );

  const subscriptionIntegrationConfigs = useMemo(() => {
    return simplifySubscriptionIntegrationConfigs(
      dataSubscriptionIntgConfigs?.subscription_integration_configs
    );
  }, [dataSubscriptionIntgConfigs]);

  const projectIntegrationConfigs = useMemo(() => {
    return simplifyProjectIntegrationConfigs(
      dataProjectIntgConfigs?.project_integration_configs
    );
  }, [dataProjectIntgConfigs]);

  const systemIntegrationConfigs = useMemo(() => {
    return simplifySystemIntegrationConfigs(
      dataSystemIntgConfigs?.system_integration_configs
    );
  }, [dataSystemIntgConfigs]);

  useEffect(() => {
    if (
      !dataAvailableIntegrations ||
      !projectIntegrationConfigs ||
      !systemIntegrationConfigs
    )
      return;
    dispatch({
      type: "SET_SHARED_STATE",
      payload: {
        subscriptionIntegrationConfigs,
        projectIntegrationConfigs,
        systemIntegrationConfigs,
        availableIntegrations: dataAvailableIntegrations.integrations
      }
    });
  }, [
    dataAvailableIntegrations,
    projectIntegrationConfigs,
    subscriptionIntegrationConfigs,
    systemIntegrationConfigs
  ]);

  const onProjectIntegrated = async (response: any) => {
    await invokeACCProjectSyncJob(
      {
        project_config_id: response.data[0]
      },
      tokenRetrievalState.token
    );
  };

  return (
    <ProjectIntegrationsTabSharedContext.Provider value={providerValue}>
      <div className="bg-white h-[calc(100vh-100px)]">
        {sharedState.activeView === "list" && <ProjectIntegrationsList />}

        {sharedState.system === IntegrationType[IntegrationType.PROCORE] &&
          sharedState.activeView === "configure-initial" && (
            <ProjectProcoreInitialIntegration
              onProjectIntegrated={onProjectIntegrated}
            />
          )}
        {(sharedState.system === IntegrationType[IntegrationType.AUTODESK] ||
          sharedState.system === IntegrationType[IntegrationType.BIM360]) &&
          sharedState.activeView === "configure-initial" && (
            <ProjectAutodeskInitialIntegration
              onProjectIntegrated={onProjectIntegrated}
            />
          )}
        {sharedState.system === IntegrationType[IntegrationType.PROCORE] &&
          sharedState.activeView === "details" && (
            <ProjectProcoreIntegrationDetails />
          )}
        {(sharedState.system === IntegrationType[IntegrationType.AUTODESK] ||
          sharedState.system === IntegrationType[IntegrationType.BIM360]) &&
          sharedState.activeView === "details" && (
            <ProjectAutodeskIntegrationDetails />
          )}
      </div>
    </ProjectIntegrationsTabSharedContext.Provider>
  );
}
export default ProjectIntegrationsTab;
