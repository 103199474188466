import {
  InfoFeatureType,
  TAuditChangeLog
} from "change-events/change-event-polling";
import { ProjectContext, TProjectContext } from "context/ProjectProvider";
import {
  FEATURE_LIST_BY_FEATURE_TYPE_ID,
  GET_CHILD_FEATURE_LINKS,
  GET_PARENT_FEATURE_LINKS
} from "entity-app/graphQL/ciq-feature-queries";
import { getGQLQueryData, modifyFeatureLinks } from "entity-app/services";
import { useCallback, useContext, useEffect, useRef, useState } from "react";

interface FeatureLink {
  link_type: number;
  id: string;
  created_at: string;
  created_by: string;
  description: string | null;
  external_source_id: string | null;
  feature_type_id: number;
  number: string;
  updated_at: string;
  updated_by: string;
  title: string;
}

function useFeatureToFeatureLinks(params: {
  token: string;
  featureInstanceId: string;
  featureTypeId: number;
  linkType?: "parent" | "child";
  loadFeaturesList?: boolean;
  loadLinkedFeatures?: boolean;
}): {
  isLoadingLinkedFeatures: boolean;
  linkedFeatures: any;
  linkedFeaturesError: any;
  saveLinks: (payload: any) => Promise<any>;
  featuresList: any;
  isLoadingFeaturesList: boolean;
  featuresListError: any;
  isSavingLinks: boolean;
  resetAllStates: () => void;
  resetLinkedFeaturesStates: () => void;
  resetFeaturesListStates: () => void;
} {
  const {
    token,
    featureInstanceId,
    featureTypeId,
    linkType = "parent",
    loadFeaturesList = false,
    loadLinkedFeatures = false
  } = params;

  const { eventLogs }: TProjectContext = useContext(ProjectContext);

  const [linkedFeatures, setLinkedFeatures] = useState<any>(null);
  const [isLoadingLinkedFeatures, setIsLoadingLinkedFeatures] = useState(true);
  const [linkedFeaturesError, setLinkedFeaturesError] = useState<any>(null);

  const [featuresList, setFeaturesList] = useState<any>(null);
  const [isLoadingFeaturesList, setIsLoadingFeaturesList] = useState(true);
  const [featuresListError, setFeaturesListError] = useState<any>(null);

  const [isSavingLinks, setIsSavingLinks] = useState(false);

  const getLinkedFeatures = useCallback(async () => {
    if (!token || !featureInstanceId || !featureTypeId) {
      return;
    }
    setIsLoadingLinkedFeatures(true);
    setLinkedFeaturesError(null);
    const response = await getGQLQueryData(
      token,
      linkType === "parent"
        ? GET_PARENT_FEATURE_LINKS
        : GET_CHILD_FEATURE_LINKS,
      {
        featureInstanceId,
        featureTypeId
      }
    );
    setIsLoadingLinkedFeatures(false);

    if (response.success) {
      const normalizedLinks: FeatureLink[] =
        response.data.data.feature_linking.map((link: any) => {
          return {
            ...link,
            ...link.target_feature_instance,
            ...link.source_feature_instance
          };
        });

      // Sort the links by the title
      normalizedLinks.sort((a: FeatureLink, b: FeatureLink) =>
        a.title.localeCompare(b.title)
      );

      setLinkedFeatures(normalizedLinks);
    } else {
      setLinkedFeaturesError(response.errorMsg);
    }
  }, [featureInstanceId, featureTypeId, linkType, token]);

  const getFeaturesList = useCallback(async () => {
    if (!token || !featureTypeId || !loadFeaturesList) {
      return;
    }
    setIsLoadingFeaturesList(true);
    setFeaturesListError(null);
    const response = await getGQLQueryData(
      token,
      FEATURE_LIST_BY_FEATURE_TYPE_ID,
      {
        featureTypeId
      }
    );
    // console.log("getFeaturesList response ", response);
    if (response.success) {
      const features = response.data.data.feature_instance.length
        ? response.data.data.feature_instance
        : null;
      setFeaturesList(features);
    } else {
      setFeaturesListError(response.errorMsg);
    }
    setIsLoadingFeaturesList(false);
  }, [featureTypeId, token, loadFeaturesList]);

  const saveLinks = useCallback(
    async (payload: any) => {
      setIsSavingLinks(true);
      const response = await modifyFeatureLinks(token, payload);
      // console.log("saveLinks response ", response);
      if (response.success) {
        getLinkedFeatures();
      }
      setIsSavingLinks(false);
      return response;
    },
    [getLinkedFeatures, token]
  );

  const resetLinkedFeaturesStates = useCallback(() => {
    setLinkedFeatures(null);
    setIsLoadingLinkedFeatures(true);
    setLinkedFeaturesError(null);
  }, []);

  const resetFeaturesListStates = useCallback(() => {
    setFeaturesList(null);
    setIsLoadingFeaturesList(true);
    setFeaturesListError(null);
  }, []);

  const resetAllStates = useCallback(() => {
    resetLinkedFeaturesStates();
    resetFeaturesListStates();
    setIsSavingLinks(false);
  }, [resetLinkedFeaturesStates, resetFeaturesListStates]);

  useEffect(() => {
    if (loadFeaturesList) {
      getFeaturesList();
    }
    if (loadLinkedFeatures) {
      getLinkedFeatures();
    }
  }, [
    getLinkedFeatures,
    getFeaturesList,
    loadFeaturesList,
    loadLinkedFeatures
  ]);

  const previousEventLogs = useRef(eventLogs);

  useEffect(() => {
    if (eventLogs?.length && previousEventLogs.current !== eventLogs) {
      const allImpactingFeatureTypes: any = [
        InfoFeatureType.bidPackage,
        InfoFeatureType.designPackage
      ];
      const conditionForBidPackage = (log: TAuditChangeLog) =>
        allImpactingFeatureTypes.includes(log?.info?.feature_type);

      if (eventLogs.some(conditionForBidPackage)) {
        getFeaturesList();
        getLinkedFeatures();
      }
    }
    previousEventLogs.current = eventLogs;
  }, [eventLogs, getFeaturesList, getLinkedFeatures]);

  return {
    isLoadingLinkedFeatures,
    linkedFeatures,
    linkedFeaturesError,
    saveLinks,
    featuresList,
    isLoadingFeaturesList,
    featuresListError,
    isSavingLinks,
    resetAllStates,
    resetLinkedFeaturesStates,
    resetFeaturesListStates
  };
}

export default useFeatureToFeatureLinks;
