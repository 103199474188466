import { Select } from "antd";
import { DefaultOptionType } from "antd/lib/select";
import UserTag from "components/user-tag/user-tag";
import { AcceptanceStatus } from "constants/index";
import { useProjectParticipants } from "hooks/project-participants";

const { Option } = Select;

function GCReviewersDropdown(props: {
  value?: any;
  notFoundContent?: any;
  onChange?:
    | ((value: any, option: DefaultOptionType | DefaultOptionType[]) => void)
    | undefined;
  disabled?: boolean;
  autoFocus?: boolean | undefined;
}) {
  const {
    value,
    notFoundContent,
    onChange,
    disabled = false,
    autoFocus
  } = props;
  const { projectParticipants } = useProjectParticipants();
  const gcReviewers = projectParticipants?.gcReviewers?.filter((user: any) => {
    const userId = value;
    if (userId && userId === user.id) {
      return true;
    }
    return user.status_id !== AcceptanceStatus.DEACTIVATED;
  });

  return (
    <Select
      className="w-full"
      autoFocus={autoFocus}
      loading={!projectParticipants?.dataLoaded}
      value={gcReviewers?.length > 0 ? value || undefined : undefined}
      onChange={onChange}
      showSearch
      filterOption
      optionFilterProp="label"
      notFoundContent={notFoundContent}
      disabled={disabled}
    >
      {gcReviewers.map((user: any) => {
        if (
          (user.status_id === AcceptanceStatus.DEACTIVATED &&
            user.id !== value?.toString()) ??
          ""
        )
          return "";

        const selectedAndInactive =
          (user.status_id === AcceptanceStatus.DEACTIVATED &&
            user.id === value?.toString()) ??
          "";

        return (
          <Option
            key={user.id}
            value={user.id}
            label={`${user.first_name} ${user.last_name} ${user?.company?.name}`}
            className={selectedAndInactive ? "hidden" : ""}
            firstName={user.first_name}
            lastName={user.last_name}
          >
            {user.status_id === AcceptanceStatus.DEACTIVATED && (
              <div className="absolute flex w-full items-center justify-end pr-3 -mt-[1px]">
                <UserTag label="Inactive" />
              </div>
            )}
            <div>
              {user.first_name} {user.last_name}
            </div>
            <div className="text-sm text-gray-500">{user?.company?.name}</div>
          </Option>
        );
      })}
    </Select>
  );
}

export default GCReviewersDropdown;
