import { GridApi } from "ag-grid-community";
import { FeatureTypes } from "entity-app/constants";

export const resetGridFilters = (props: {
  setListFilter: any;
  api: GridApi | undefined;
}) => {
  const { setListFilter, api } = props;
  try {
    const currentFilters: any = api?.getFilterModel();
    const currentFilterCols = Object.keys(currentFilters);
    currentFilterCols.forEach((col: string) => {
      api?.destroyFilter(col);
    });

    setTimeout(() => {
      setListFilter(null);
      api?.onFilterChanged();
    });
  } catch (ex) {
    //
  }
};

export function getFeatureTypeString(id: number): FeatureTypes {
  return Object.keys(FeatureTypes).find(
    (key) => FeatureTypes[key as keyof typeof FeatureTypes] === id
  )! as unknown as FeatureTypes;
}

/**
 * Method to checlk if the given instance id is present in the event log.
 * @param featureInstanceId
 * @param eventLog
 * @param featureId
 * @returns
 */
export function hasTheFeature(
  featureInstanceId: string,
  eventLog: any,
  featureId: string
) {
  let hasFeature = false;
  if (featureId) {
    const objectId = eventLog.object_id;
    const { info } = eventLog;
    hasFeature =
      featureInstanceId === objectId ||
      info?.feature_instance_ids?.includes(featureInstanceId) ||
      info?.linked_features?.some((feature: any) => {
        return feature?.id === featureInstanceId;
      });
  }
  return hasFeature;
}
