import { InboxOutlined } from "@ant-design/icons";
import { Button, message, Modal, Spin, UploadFile, UploadProps } from "antd";
import { RcFile } from "antd/lib/upload";
import Dragger from "antd/lib/upload/Dragger";
import { useGetProjectINTGState } from "hooks/submittal";
import { useState } from "react";
import { noAuthClient } from "services/axios";
import { generateS3URL } from "services/file-mgmt";
import { invokeACCProjectSyncJob } from "services/integration-service";

export default function ImportACCSubmittalLogs({
  tokenRetrievalState,
  gqlClientForProject,
  showImportLogDrawer,
  setDrawerOpen,
  onCancel,
  currentSourceType
}: {
  tokenRetrievalState: { token: string };
  gqlClientForProject: any;
  showImportLogDrawer: boolean;
  onCancel: () => void;
  setDrawerOpen: any;
  currentSourceType?: string;
}) {
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [uploading, setUploading] = useState(false);
  const [fileProcessing, setfileProcessing] = useState(false);
  const [unsupportedFileSelected, setUnsupportedFileSelected] = useState(false);

  const { data: projectIntegrationData } = useGetProjectINTGState({
    client: gqlClientForProject
  });

  const syncData = async (attachment_id: string) => {
    const projConfigId: string =
      projectIntegrationData.project_integration_configs[0].id;

    const invokeSyncResponse = await invokeACCProjectSyncJob(
      {
        project_config_id: projConfigId,
        attachment_id
      },
      tokenRetrievalState.token
    );
    setUploading(false);
    setfileProcessing(false);
    if (invokeSyncResponse.success) {
      message.info("Import Started");
      setFileList([]);
      setDrawerOpen(false);
    }
  };

  const handleUpload = async () => {
    if (!fileList?.length) return;
    const file = fileList[0];
    setUploading(true);
    setfileProcessing(true);
    const signedUrlRespose: any = await generateS3URL(
      {
        feature: "SUBMITTAL_INTEGRATION",
        file_name: file.name
      },
      tokenRetrievalState.token
    );

    if (signedUrlRespose.error) {
      message.error(signedUrlRespose.error.msg);
      return;
    }

    const fileUploadResponse: any = await noAuthClient.put(
      `${signedUrlRespose.data.success.url}`,
      file
    );

    if (fileUploadResponse.error) {
      message.error("Submittal Log failed to import.", 20000);
    } else {
      syncData(signedUrlRespose.data.success.attachment_id);
    }
  };

  const props: UploadProps = {
    accept:
      "text/csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    style: { borderColor: unsupportedFileSelected === true ? "red" : "" },
    fileList,
    onRemove: () => {
      setFileList([]);
    },
    onDrop: (e) => {
      if (e.dataTransfer.files.length > 0) {
        const file = e.dataTransfer.files[0];
        if (
          file.type !== "text/csv" &&
          file.type !==
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        ) {
          setUnsupportedFileSelected(true);
          setFileList([]);
          message.error("Please select csv file only");
          return false;
        }
        setUnsupportedFileSelected(false);
        return false;
      }
      return false;
    },
    beforeUpload: (file: RcFile) => {
      if (
        file.type !== "text/csv" &&
        file.type !==
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      ) {
        setUnsupportedFileSelected(true);
        setFileList([]);
        message.error("Please select csv or xlsx files only");
        return false;
      }
      setFileList([file]);
      setUnsupportedFileSelected(false);
      return false;
    },
    showUploadList: {
      showDownloadIcon: false,
      showPreviewIcon: false
    }
  };

  return (
    <Modal
      className="custom-drawer"
      title="Import Log"
      width={420}
      style={{
        right: 0,
        bottom: 0,
        top: 40,
        padding: 0,
        position: "absolute"
      }}
      bodyStyle={{ height: "calc(100vh - 92px)" }}
      footer={null}
      open={showImportLogDrawer}
      onCancel={onCancel}
      destroyOnClose
    >
      <div className="px-3">
        <p className=" font-normal text-sm text-[#000000CC]">
          Upload Submittal Log from{" "}
          <span className=" font-semibold">{currentSourceType}</span>
        </p>
        <div>
          <Dragger {...props} disabled={fileProcessing}>
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            {fileList.length > 0 ? (
              <p className="ant-upload-text">{fileList[0].name}</p>
            ) : (
              <div>
                <p className="ant-upload-text">Click or drag file to upload.</p>
                <p className=" font-medium text-[#626262CC] text-xs">
                  Only .xlsx and .csv files are supported.
                </p>
              </div>
            )}
          </Dragger>
        </div>
        <div className="flex flex-col justify-center items-center">
          {fileProcessing ? (
            <Spin
              className="mt-4 text-red-500"
              tip="File is being processed.Please do not close page while processing"
              size="default"
            />
          ) : (
            <Button
              type="primary"
              onClick={handleUpload}
              disabled={fileList.length === 0}
              loading={uploading}
              style={{ marginTop: 16 }}
            >
              {uploading ? "Importing" : "Import"}
            </Button>
          )}
        </div>
      </div>
    </Modal>
  );
}
