import { Popover, Space, Typography } from "antd";

const { Text } = Typography;

function DisplayNameListPopover({
  list,
  children
}: {
  list: Array<{ name: string; id?: string }>;
  children: any;
}) {
  const content = (
    <div className="overflow-y-auto max-h-96 space-y-2 flex-col flex">
      {list.map((l) => (
        <div
          className="w-60 px-2 border border-solid border-gray-300"
          key={l.name}
        >
          <Text
            className="w-full py-1"
            ellipsis={{ tooltip: l.name, suffix: "" }}
          >
            {l.name}
          </Text>
        </div>
      ))}
    </div>
  );
  return (
    <Popover content={content}>
      <Space className="px-1 cursor-pointer text-blue-600">{children}</Space>
    </Popover>
  );
}
export default DisplayNameListPopover;
