import { ColDef, ColGroupDef } from "ag-grid-community";
import { DateFilter, DateUtils } from "utils/dateutils";
import { FeatureTypes } from "entity-app/constants";
import EntityDropdownCellEditor from "components/cell-editor/drop-down-editor";
import { linkCellRenderer, userEntityCellRenderer } from "./cell-renders";

import CustomDateFilters from "../../../pages/submittals-list/custom-date-filter";

export const coldefDateField = (def: ColDef<any>) => {
  const { field, headerName } = def;
  if (!field) return {} as ColDef<any>;
  return {
    colId: field,
    field,
    headerName,
    headerTooltip: headerName,
    tooltipValueGetter: ({ value }) => value,
    valueGetter: ({ data }) => {
      return data[field] ? DateUtils.format(data[field]) : "";
    },
    comparator: DateFilter.comparator,
    filter: CustomDateFilters,
    filterParams: {
      title: headerName || "",
      columnData: {
        field,
        header: headerName
      }
    },
    ...def
  } as ColDef<any> | ColGroupDef<any>;
};

export const coldefHiddenField = (def: ColDef<any>) => {
  const { field, headerName } = def;
  return {
    colId: field,
    field,
    headerName,
    editable: false,
    hide: true,
    suppressColumnsToolPanel: true,
    filter: false,
    menuTabs: [],
    getQuickFilterText: () => "",
    ...def
  } as ColDef<any> | ColGroupDef<any>;
};

export const coldefSingleField = (def: ColDef<any>) => {
  const { field, headerName } = def;
  return {
    colId: field,
    field,
    headerName,
    headerTooltip: headerName,
    tooltipField: field,
    cellClass: (params) => {
      const isCellEditble = params?.context?.isGridCellEditable;
      return isCellEditble && isCellEditble(params) ? "cell-editable" : "";
    },
    ...def
  } as ColDef<any> | ColGroupDef<any>;
};

export const coldefNavigation = (
  def: ColDef<any>,
  projectId: string,
  featureKey: string
) => {
  return {
    ...coldefSingleField(def),
    cellRenderer: linkCellRenderer,
    cellRendererParams: {
      projectId,
      featureKey
    }
  } as ColDef<any> | ColGroupDef<any>;
};

export const coldefUser = ({
  user,
  def
}: {
  def: ColDef<any>;
  user: {
    id: string;
    firstName: string;
    lastName: string;
    statusId?: string;
    dropdownType?: string;
    featureTypeId?: FeatureTypes;
  };
}) => {
  const { headerName } = def;
  return {
    colId: user.id,
    headerName,
    headerTooltip: headerName,
    valueGetter: ({ data }) => {
      if (!data[user.id]) return "";
      const firstName = data[user.firstName] || "";
      const lastName = data[user.lastName] || "";
      return `${firstName} ${lastName}`;
    },
    tooltipValueGetter: ({ value }) => value,
    cellRenderer: userEntityCellRenderer,
    cellRendererParams: {
      statusId: user.statusId
    },
    cellEditor: EntityDropdownCellEditor,
    cellEditorParams: {
      user
    },
    cellClass: (params) => {
      const isCellEditble = params?.context?.isGridCellEditable;
      return isCellEditble && isCellEditble(params) ? "cell-editable" : "";
    },
    ...def
  } as ColDef<any> | ColGroupDef<any>;
};
