export const BID_PACKAGE_LIST_VIEW = `BidPackageListQuery {
  bid_package_list_view {
    id
    auto_inc_value
    number
    title
    description
    wave
    bid_scope
    workflow_status
    milestone_state
    next_planned_deadline
    due_date
    risk
    float
    feature_workflow_instance_id
    awarded_subcontractor_id
    awarded_subcontractor_name
    awarded_value
    estimated_value
    preconstruction_lead_first_name
    preconstruction_lead_id
    operations_lead_status_id
    preconstruction_lead_status_id
    preconstruction_lead_last_name
    operations_lead_first_name
    operations_lead_id
    operations_lead_last_name
    bid_package_template_id
    bid_package_template_name
    bid_package_template_id
    no_of_bidders
    bidding_subcontractors
    milestones
    offsets
  }
}`;

export const QUERY_GET_BID_PACKAGE_COLUMN_CONFIG = `
  GetSavedBidPackageColumnHeaders($where: project_users_bool_exp) {
    project_users(where: $where) {
      project_id
      bid_package_column_config
    }
  }
`;
