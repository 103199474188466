import { message } from "antd";
import { ProjectContext, TProjectContext } from "context/ProjectProvider";
import { getGQLQueryData } from "entity-app/services";
import { useCIQMutation } from "hooks/ciq-gql-hooks";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { noAuthClient } from "services/axios";
import { generateS3URL } from "services/file-mgmt";
import { MUTATION_INSERT_COMMENT } from "services/graphQL/mutations";
import debounce from "lodash.debounce";
import { FeatureComments } from "entity-app/models/feature-comments";
import { GET_FEATURE_COMMENTS } from "entity-app/graphQL/ciq-feature-queries";
import { hasTheFeature } from "entity-app/utils/utils";
import { ErrorMessages } from "../../constants";

export const useFeatureComments = (
  featureId: string,
  featureKey: string
): {
  comments: FeatureComments;
  submitComment: Function;
  isSubmittingComment: boolean;
  submittingCommentId: string;
} => {
  const {
    tokenRetrievalState,
    gqlClientForProject,
    eventLogs
  }: TProjectContext = useContext(ProjectContext);
  const token = tokenRetrievalState?.token;

  const [comments, setComments] = useState<any>([]);
  const [isSubmittingComment, setIsSubmittingComment] = useState<any>(false);
  const [submittingCommentId, setSubmittingCommentId] = useState<any>(false);

  const [mutationInsertComment] = useCIQMutation(MUTATION_INSERT_COMMENT, {
    client: gqlClientForProject
  });

  const getComments = useCallback(async () => {
    if (!token) {
      return;
    }

    const response = await getGQLQueryData(token, GET_FEATURE_COMMENTS, {
      where: {
        [featureKey]: { _eq: featureId }
      }
    });

    if (response.success) {
      setComments(response.data?.data);
    }
  }, [token, featureKey, featureId]);

  const debouncedGetCommentsRef: any = useRef<(() => void) | null>(null);

  useEffect(() => {
    debouncedGetCommentsRef.current = debounce(getComments, 3000);
    return () => {
      debouncedGetCommentsRef.current?.cancel();
    };
  }, [getComments]);

  const uploadFile = useCallback(
    async (fileInfo: any) => {
      const promise = new Promise((resolve, reject) => {
        const initUploadFile = async () => {
          try {
            const signedUrlRespose: any = await generateS3URL(
              fileInfo.S3Data,
              token
            );

            if (signedUrlRespose.error) {
              reject(signedUrlRespose.error);
              return;
            }

            if (signedUrlRespose?.data?.success?.url) {
              const fileUploadResponse = await noAuthClient.put(
                `${signedUrlRespose.data.success.url}`,
                fileInfo.file
              );
              resolve({ signedUrlRespose, fileUploadResponse, fileInfo });
            }
          } catch (error: any) {
            reject(error);
          }
        };
        initUploadFile();
      });
      return promise;
    },
    [token]
  );

  const submitComment = useCallback(
    async (commentText: string, filesToUpload: []) => {
      setIsSubmittingComment(true);

      const commentData = {
        [featureKey]: featureId,
        description: commentText.trim()
      };

      const payload: any = {
        variables: {
          object: commentData
        }
      };

      const commentResponse: any = await mutationInsertComment(payload);

      const commentId = commentResponse.data?.insert_comment_one?.id;
      if (!commentId) {
        setIsSubmittingComment(false);
        return commentResponse;
      }

      setSubmittingCommentId(commentId);
      if (filesToUpload.length) {
        const fileUploadPromises: any = [];
        filesToUpload.forEach(async (fileInfo: any) => {
          const updatedfileInfo = {
            ...fileInfo,
            S3Data: {
              feature: "COMMENT",
              feature_id: commentId,
              file_name: fileInfo.name
            }
          };
          fileUploadPromises.push(uploadFile(updatedfileInfo));
        });
        try {
          await Promise.all(fileUploadPromises);
          // console.log("allfileUploadResponse ", allfileUploadResponse);
        } catch (ex: any) {
          console.log("allfileUploadResponse : ", ex);
          const errorMsg: string =
            ex.response?.data?.error ||
            ex.message ||
            ErrorMessages.comments.addAttachmentFailed;
          message.error(errorMsg);
        }
      }
      setIsSubmittingComment(false);
      setSubmittingCommentId("");
      getComments();
      return commentResponse;
    },
    [featureId, featureKey, getComments, mutationInsertComment, uploadFile]
  );

  useEffect(() => {
    getComments();
  }, [getComments]);

  // Poll the changes and update the respective UI components
  const previousEventLogs = useRef(eventLogs);

  useEffect(() => {
    if (eventLogs?.length && previousEventLogs.current !== eventLogs) {
      const isCommentOrAttachmentEvent = eventLogs.some((log) => {
        const info: any = log?.info;
        const isCommnetOrAttachmentChanged =
          log?.data_source === "comment" ||
          log?.data_source === "attachments" ||
          info?.table_name === "comment" ||
          info?.table_name === "attachments";
        const isRelatedToCurrentFeature: boolean = hasTheFeature(
          featureId,
          log,
          featureId
        );

        return isCommnetOrAttachmentChanged && isRelatedToCurrentFeature;
      });

      if (isCommentOrAttachmentEvent) {
        debouncedGetCommentsRef.current?.();
      }
    }
    previousEventLogs.current = eventLogs;
  }, [eventLogs, featureId]);

  return {
    comments,
    submitComment,
    isSubmittingComment,
    submittingCommentId
  };
};
