import { Select } from "antd";
import { DefaultOptionType } from "antd/lib/select";
import { ProjectContext } from "context/ProjectProvider";
import { useCIQQuery } from "hooks/ciq-gql-hooks";
import { useProjectParticipants } from "hooks/project-participants";
import {
  forwardRef,
  useContext,
  useImperativeHandle,
  useMemo,
  useState
} from "react";
import { QUERY_GET_PROJECT_SPECS } from "services/graphQL/queries";

type TDropdownProps = {
  selectedIds: Array<string>;
  selectedOptions: DefaultOptionType[];
  onChange: (
    value: any,
    option: DefaultOptionType | DefaultOptionType[]
  ) => void;
};

function DropdownSpecSectionNames({
  selectedIds,
  selectedOptions,
  onChange
}: TDropdownProps) {
  const { gqlClientForProject } = useContext(ProjectContext);
  const { data: specSections, loading } = useCIQQuery<{
    spec_sections: Array<{
      title: string;
      id: string;
      section_number: string;
    }>;
  }>(QUERY_GET_PROJECT_SPECS, {
    client: gqlClientForProject,
    skip: !gqlClientForProject
  });

  return (
    <Select
      className="w-full"
      mode="multiple"
      value={selectedIds}
      loading={loading}
      options={
        specSections && specSections.spec_sections
          ? specSections.spec_sections.map((spec) => ({
              value: spec.id,
              label: spec.title
                ? `${spec.section_number} - ${spec.title}`
                : spec.section_number
            }))
          : selectedOptions
      }
      showArrow
      onChange={onChange}
    />
  );
}

function DropdownCompanyNames({
  selectedIds,
  selectedOptions,
  onChange
}: TDropdownProps) {
  const { projectParticipants } = useProjectParticipants();
  const subContractors = projectParticipants?.responsibleContractors;
  return (
    <Select
      className="w-full"
      mode="multiple"
      value={selectedIds}
      loading={!projectParticipants?.dataLoaded}
      options={
        subContractors && subContractors.length
          ? subContractors.map((company: any) => ({
              label: company.subscription_vendor.name,
              value: company.vendor_id
            }))
          : selectedOptions
      }
      showArrow
      onChange={onChange}
    />
  );
}

const DropdownMultiSelectCellEditor = forwardRef((props: any, ref) => {
  const { value, dropdownType } = props;
  const [selectedIds, setSelectedIds] = useState(
    value?.map((x: any) => x.id) || []
  );
  const [selectedOptions, setSelectedOptions] = useState(
    value?.map((x: any) => ({ value: x.id, label: x.name })) || []
  );

  useImperativeHandle(ref, () => {
    return {
      getValue() {
        return selectedOptions.map((x: any) => ({
          id: x.value,
          name: x.label
        }));
      }
    };
  });

  const dropdownProps = useMemo(
    () =>
      ({
        selectedIds,
        selectedOptions,
        onChange: (v, o) => {
          setSelectedIds(v);
          setSelectedOptions(o);
        }
      } as TDropdownProps),
    [selectedIds, selectedOptions]
  );

  const dropDrop = useMemo(() => {
    switch (dropdownType) {
      case "spec_dropdown":
        return <DropdownSpecSectionNames {...dropdownProps} />;
      default:
        return <DropdownCompanyNames {...dropdownProps} />;
    }
  }, [dropdownProps, dropdownType]);

  return <div className="h-full flex items-end">{dropDrop}</div>;
});

export default DropdownMultiSelectCellEditor;
