import { Select } from "antd";
import { useContext } from "react";
import { ProjectContext } from "context/ProjectProvider";
import { useCIQQuery } from "hooks/ciq-gql-hooks";
import { GET_PROJECT_WORKFLOW_TEMPLATE_NAMES } from "services/graphQL/ciq-queries";

function FeatureWFTemplateDropdown(props: any) {
  const { featureId, value, onChange, disabled = false } = props;
  const { gqlClientForProject } = useContext(ProjectContext);
  const { data: projectFeatureWFTemplates, loading } = useCIQQuery<{
    project_workflow_templates: Array<any>;
  }>(GET_PROJECT_WORKFLOW_TEMPLATE_NAMES, {
    client: gqlClientForProject,
    variables: { featureId }
  });

  const dropdownOptions =
    projectFeatureWFTemplates?.project_workflow_templates?.map(
      (template: any) => ({
        label: template.name,
        value: template.id
      })
    ) || [];

  // console.log("dropdownOptions ", dropdownOptions);

  return (
    <Select
      options={dropdownOptions}
      placeholder="Select a template"
      loading={loading}
      value={dropdownOptions?.length > 0 ? value || undefined : undefined}
      onChange={onChange}
      disabled={disabled}
      data-testid="feature-wf-template-dropdown"
    />
  );
}

export default FeatureWFTemplateDropdown;
