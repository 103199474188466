import { Input } from "antd";
import { forwardRef, useState, useImperativeHandle } from "react";

const currencyCellEditor = forwardRef((props: any, ref) => {
  const [value, setValue] = useState(props.value);

  useImperativeHandle(ref, () => {
    return {
      getValue() {
        return value;
      }
    };
  });

  return (
    <div className="h-full flex items-end">
      <Input
        autoFocus
        type="number"
        value={value}
        onKeyDown={(evt) => evt.key === "e" && evt.preventDefault()}
        onChange={(event: any) => {
          setValue(event.target.value);
        }}
        min="0"
      />
    </div>
  );
});

export default currencyCellEditor;
