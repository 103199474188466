/* eslint-disable @typescript-eslint/naming-convention */
import { Button, Divider, Form, Input, InputNumber, message } from "antd";
import { useContext, useEffect, useMemo, useState } from "react";
import { ProjectContext, TProjectContext } from "context/ProjectProvider";
import GCReviewersDropdown from "components/gc-reveiewer-dropdown";
import { restrictInputToNumbers } from "utils/inpututils";
import FeatureWFTemplateDropdown from "pages/preconstruction-details/components/feature-wf-template-dropdown";
import { FeatureTypes } from "entity-app/constants";
import { useCIQMutation } from "hooks/ciq-gql-hooks";
import { UPDATE_BID_PACKAGE_DETAILS_MULTI } from "entity-app/graphQL/ciq-feature-mutations";
import { gql } from "@apollo/client";
import DisplayNameListPopover from "components/bulk-edit-material/display-name-list";
import { compareObjectPropertyValues } from "utils/utils";
import {
  EWorkflowStatusDataBlock,
  UserParticipationType
} from "../../../../constants";

interface ISelectedFeatureProperties {
  id: string;
  number: string;
  title: string;
  wave: string;
  no_of_bidders: number;
  operations_lead_id: string;
  preconstruction_lead_id: string;
  bid_package_template_id: string;
  workflow_status: EWorkflowStatusDataBlock;
}

interface IBulkEditPayload {
  participants:
    | {
        instance_id: string;
        user_id: string;
        user_participation_type_id: number;
      }[]
    | null;
  featureInstanceIds: string[];
  bidPackage: {
    no_of_bidders: number;
    wave: string;
  };
  workFlowTemplateId: string;
}

interface IBulkEditFormValues {
  bid_package_template_id: string;
  no_of_bidders: number;
  operations_lead_id: string;
  preconstruction_lead_id: string;
  wave: string;
}

const mapAndSortFeatures = (features: ISelectedFeatureProperties[]) =>
  features
    .map((feature) => ({
      name: `${feature.number} - ${feature.title}`,
      id: feature.id
    }))
    .sort((a, b) => a.name.localeCompare(b.name));

function BulkEditBidPackageDetails(props: {
  selectedRows: ISelectedFeatureProperties[];
  onCancel: () => void;
  onUpdateSuccess: () => void;
}) {
  const { selectedRows, onCancel, onUpdateSuccess } = props;

  // console.log("selectedRows ", selectedRows);

  const { gqlClientForProject }: TProjectContext = useContext(ProjectContext);

  const [mutationBulkUpdateBidPackageDetails] = useCIQMutation(
    gql(UPDATE_BID_PACKAGE_DETAILS_MULTI),
    {
      client: gqlClientForProject
    }
  );

  const [form] = Form.useForm();

  const [isSaving, setIsSaving] = useState(false);

  const [isFormModified, setIsFormModified] = useState(false);

  const featuresByWFStatusNameAndNumber = useMemo(() => {
    const featuresWFNotStarted = selectedRows.filter(
      (row) => row.workflow_status === EWorkflowStatusDataBlock.WF_NOT_STARTED
    );
    const featuresWFStarted = selectedRows.filter(
      (row) => row.workflow_status !== EWorkflowStatusDataBlock.WF_NOT_STARTED
    );
    return {
      NotStarted: mapAndSortFeatures(featuresWFNotStarted),
      Started: mapAndSortFeatures(featuresWFStarted)
    };
  }, [selectedRows]);

  const incomingBulkEditValues = useMemo(() => {
    return selectedRows?.reduce((acc: any, row: any) => {
      const featureFields: Partial<ISelectedFeatureProperties> = {
        wave: row.wave,
        no_of_bidders: row.no_of_bidders,
        operations_lead_id: row.operations_lead_id,
        preconstruction_lead_id: row.preconstruction_lead_id,
        bid_package_template_id: row.bid_package_template_id,
        workflow_status: row.workflow_status
      };
      Object.keys(featureFields).forEach((key) => {
        if (acc[key]) {
          if (acc[key] !== row[key]) {
            if (typeof row[key] === "number") {
              acc[key] = null;
            } else if (typeof row[key] === "string") {
              acc[key] = "";
            } else {
              acc[key] = null;
            }
          }
        } else {
          acc[key] = row[key];
        }
      });
      return acc;
    }, {});
  }, [selectedRows]);

  useEffect(() => {
    // console.log("incomingBulkEditValues ", incomingBulkEditValues);
    form.setFieldsValue(incomingBulkEditValues);
  }, [form, incomingBulkEditValues]);

  const onFinish = async (formValues: IBulkEditFormValues) => {
    setIsSaving(true);

    const {
      no_of_bidders,
      wave,
      bid_package_template_id,
      operations_lead_id,
      preconstruction_lead_id
    } = formValues;
    const featureIds = selectedRows.map(({ id }) => id);

    const participants: IBulkEditPayload["participants"] = [];

    selectedRows.forEach((row) => {
      if (operations_lead_id) {
        participants.push({
          instance_id: row.id,
          user_id: operations_lead_id,
          user_participation_type_id: UserParticipationType.OPERATIONS_LEAD
        });
      }
      if (preconstruction_lead_id) {
        participants.push({
          instance_id: row.id,
          user_id: preconstruction_lead_id,
          user_participation_type_id: UserParticipationType.PRECONSTRUCTION_LEAD
        });
      }
    });

    const variables: IBulkEditPayload = {
      participants: participants.length > 0 ? participants : null,
      featureInstanceIds: featureIds,
      bidPackage: { no_of_bidders, wave },
      workFlowTemplateId: bid_package_template_id
    };

    // console.log("variables ", variables);

    const updateResponse = await mutationBulkUpdateBidPackageDetails({
      variables
    });

    // console.log("updateResponse ", updateResponse);

    if (updateResponse.success) {
      message.success("Bid packages updated successfully");
      onUpdateSuccess();
    }

    setIsSaving(false);
  };

  const onFormFieldsChange = () => {
    const currentValues = form.getFieldsValue();
    const diff = compareObjectPropertyValues(
      [currentValues, incomingBulkEditValues],
      Object.keys(currentValues).map((key) => ({
        field: key,
        label: key
      }))
    );
    setIsFormModified(diff.diffFoundSummary);
  };

  return (
    <div>
      <div className=" mb-3 -mt-2 font-semibold">
        <span className="tracking-wide">Selected Bid Packages:</span>{" "}
        {selectedRows.length}
      </div>
      <Form
        preserve={false}
        form={form}
        scrollToFirstError
        layout="vertical"
        onFinish={onFinish}
        onFieldsChange={onFormFieldsChange}
      >
        <Form.Item label="Wave" name="wave">
          <Input />
        </Form.Item>
        <Form.Item label="PRECONSTRUCTION LEAD" name="preconstruction_lead_id">
          <GCReviewersDropdown />
        </Form.Item>
        <Form.Item label="OPERATIONS LEAD" name="operations_lead_id">
          <GCReviewersDropdown />
        </Form.Item>
        <Form.Item label="Number of Bidders" name="no_of_bidders">
          <InputNumber
            className="w-full"
            type="number"
            min={0}
            onKeyDown={restrictInputToNumbers}
          />
        </Form.Item>
        {featuresByWFStatusNameAndNumber.NotStarted.length > 0 &&
          featuresByWFStatusNameAndNumber.Started.length > 0 && (
            <div>
              <Divider className="p-0 my-2 pb-1" />
              <div className="text-xs mb-2">
                The fields below will only be updated in bid packages for which
                workflow has not started.
                <DisplayNameListPopover
                  list={featuresByWFStatusNameAndNumber.NotStarted}
                >
                  {featuresByWFStatusNameAndNumber.NotStarted.length}
                </DisplayNameListPopover>
                out of
                <DisplayNameListPopover
                  list={[
                    ...featuresByWFStatusNameAndNumber.NotStarted,
                    ...featuresByWFStatusNameAndNumber.Started
                  ].sort((a, b) => a.name.localeCompare(b.name))}
                >
                  {featuresByWFStatusNameAndNumber.NotStarted.length +
                    featuresByWFStatusNameAndNumber.Started.length}
                </DisplayNameListPopover>
                bid packages selected, will be updated.
              </div>
            </div>
          )}
        <Form.Item label="Bid Package Template" name="bid_package_template_id">
          <FeatureWFTemplateDropdown featureId={FeatureTypes.BID_PACKAGE} />
        </Form.Item>
        <div className="flex justify-end gap-x-2">
          <Button onClick={onCancel} disabled={isSaving}>
            Cancel
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            loading={isSaving}
            disabled={isSaving || !isFormModified}
            data-testid="apply-button"
          >
            Apply
          </Button>
        </div>
      </Form>
    </div>
  );
}

export default BulkEditBidPackageDetails;
