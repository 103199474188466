import { useCIQQuery } from "hooks/ciq-gql-hooks";
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { ProjectContext } from "context/ProjectProvider";
import { useMutation } from "@apollo/client";
import { MUTATION_SAVE_COLUMNS } from "services/graphQL/mutations";
import { getLoggedinUserId } from "services/auth";
import {
  EntityMilestoneAndDurationColumns,
  getMaxNumberOfMilestone
} from "entity-app/shared-components/entity-milestones-duration-columns";
import { AgGridReact } from "ag-grid-react";
import { message } from "antd";
import { UtilColumnConfig } from "components/column-config-views/column-config-util";
import {
  InfoFeatureType,
  TAuditChangeLog
} from "change-events/change-event-polling";
import {
  BID_PACKAGE_LIST_VIEW,
  QUERY_GET_BID_PACKAGE_COLUMN_CONFIG
} from "../queries";
import { TBidPackageLog } from "../model";

export const usePreconstrutionData = (props: {
  gridRef: React.RefObject<AgGridReact<any>>;
}) => {
  const { gridRef } = props;
  const { gqlClientForProject, eventLogs } = useContext(ProjectContext);

  const milestonesColumns = useMemo(
    () => EntityMilestoneAndDurationColumns(),
    []
  );
  const [maxNumberOfMilestone, setMaxNumberOfMilestone] = useState({
    numberOfMaxOffsets: 0,
    numberOfMaxMileStones: 0
  });

  const { data: bidPackageList, refetch: refetchBidPackageList } = useCIQQuery<{
    bid_package_list_view: Array<TBidPackageLog>;
  }>(BID_PACKAGE_LIST_VIEW, {
    variables: {},
    client: gqlClientForProject,
    skip: !gqlClientForProject
  });

  const { data: savedColumnsData } = useCIQQuery<{
    project_users: Array<{ bid_package_column_config: any }>;
  }>(QUERY_GET_BID_PACKAGE_COLUMN_CONFIG, {
    fetchPolicy: "no-cache",
    client: gqlClientForProject,
    skip: !gqlClientForProject,
    variables: {
      where: { user_id: { _eq: getLoggedinUserId() } }
    }
  });

  const previousEventLogs = useRef(eventLogs);

  useEffect(() => {
    if (eventLogs?.length && previousEventLogs.current !== eventLogs) {
      /**
       * If info.feature_type == "BID_PACKAGE" then on log page refresh everything
       * if any planning item changed then milestone of a few bids will might change hence need to reload the list
       */
      const allImpactingFeatureTypes: any = [
        InfoFeatureType.bidPackage,
        InfoFeatureType.designPackage,
        InfoFeatureType.planningActivity,
        InfoFeatureType.planningMaterial,
        InfoFeatureType.planningSubmittal
      ];
      const conditionForBidPackage = (log: TAuditChangeLog) =>
        allImpactingFeatureTypes.includes(log?.info?.feature_type);

      if (eventLogs.some(conditionForBidPackage)) {
        refetchBidPackageList();
      }
    }
    previousEventLogs.current = eventLogs;
  }, [eventLogs, refetchBidPackageList]);

  const calculateMaxNumberOfMilestone = useCallback(() => {
    const maxMileStone = getMaxNumberOfMilestone({
      api: gridRef.current?.api
    });
    if (!maxMileStone) return;

    setMaxNumberOfMilestone(maxMileStone);
    if (gridRef.current && maxMileStone)
      milestonesColumns.updateAllMileStoneInitialVisibility(
        gridRef.current?.columnApi,
        maxMileStone
      );
  }, [gridRef, milestonesColumns]);

  const savedColumns = useMemo(() => {
    if (!savedColumnsData || !savedColumnsData?.project_users?.length)
      return null;

    const resArr = savedColumnsData?.project_users;
    const colConfig = resArr[resArr.length - 1];
    calculateMaxNumberOfMilestone();
    return colConfig;
  }, [calculateMaxNumberOfMilestone, savedColumnsData]);

  const [saveColumnsStateMutation] = useMutation<any>(MUTATION_SAVE_COLUMNS, {
    client: gqlClientForProject
  });

  const onSaveColumnState = async () => {
    console.log("onSaveColumnState ");
    if (!gridRef.current) return;
    const columnsStates = UtilColumnConfig.getColumnStateFromGrid({
      gridRef: gridRef.current
    });

    try {
      const variables = {
        bid_package_column_config: columnsStates
      };
      const updateResponse: any = await saveColumnsStateMutation({
        variables
      });
      if (updateResponse.errors) {
        message.error({ content: updateResponse.errors[0].message });
      } else {
        // message.success("Updated successfully");
      }
    } catch (ex) {
      console.log("An error occured");
    }
  };

  return {
    bidPackageList,
    refetchBidPackageList,
    milestonesColumns,
    maxNumberOfMilestone,
    calculateMaxNumberOfMilestone,
    savedColumns,
    onSaveColumnState
  };
};
