import { useMemo } from "react";
import { INTEGRATION_IMPORT_LOGS } from "services/graphQL/ciq-queries";
import { useCIQQuery } from "./ciq-gql-hooks";

export const useGetACCImportLogData = (gqlClientForProject: any) => {
  type TImportStatus = "PROCESSING" | "PROCESSED" | "FAILED" | "NA";

  const { data: importLogData, refetch: refetchIntegrationImportLogs } =
    useCIQQuery(INTEGRATION_IMPORT_LOGS, {
      client: gqlClientForProject,
      skip: !gqlClientForProject
    });

  const lastImportStatus: any = useMemo(() => {
    if (!importLogData || !importLogData.import_log.length) return null;

    const importStatus: TImportStatus = importLogData.import_log[0].status;
    const fileData = importLogData.import_log[0]?.metadata;
    const lastImportDT: string =
      importLogData.import_log[0].metadata?.processed_at;

    return {
      status: importStatus,
      lastSyncDateTime: lastImportDT,
      fileData
    };
  }, [importLogData]);

  return { lastImportStatus, refetchIntegrationImportLogs };
};
