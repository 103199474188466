import { useMutation } from "@apollo/client";
import {
  Button,
  Form,
  FormRule,
  Input,
  Radio,
  Spin,
  Tabs,
  message
} from "antd";
import { ProjectContext } from "context/ProjectProvider";
import "../module-settings.scss";

import { useContext, useEffect, useState } from "react";
import {
  MUTATION_PROJECT_DEFAULT_CONFIG,
  MUTATION_UPDATE_PROJECT_BY_ID
} from "services/graphQL/mutations";
import DistributionGroup from "pages/distribution-group";
import {
  ThresholdType,
  WorkflowDefaultData
} from "pages/project-setting-menu/module-settings/model";
import { useHistory, useParams } from "react-router";
import classNames from "classnames";
import TextArea from "antd/lib/input/TextArea";
import { DateBlockInfoAction } from "components/date-block/models";
import { isProjectInIntegrationMode } from "utils/utils";

const submittalHeaderTitleMap: any = {
  submittal_id: "ID",
  spec_no: "Spec Section",
  submittal_number: "Submittal Number",
  title: "Submittal Title",
  description: "Description",
  revision: "Revision",
  type: "Type",
  workflow_status: "Status",
  state: "State",
  assignee: "Ball In Court",
  responsible_contractor: "Responsible Contractor",
  submitter: "Submitter",
  gc_reviewer: "GC Reviewer",
  design_reviewer: "Design Reviewer",
  assigned_to_subcontractor: "Assigned To Subcontractor (Actual)",
  submitted_by_subcontractor: "Submitted By Subcontractor (Actual)",
  submitted_for_design_review: "Submitted For Design Review (Actual)",
  design_review_completed: "Design Review Completed (Actual)",
  submittal_distributed: "Submittal Distributed (Actual)",
  linked_activities: "Linked Activities",
  linked_materials: "Linked Materials",
  next_planned_deadline: "Next Deadline",
  next_action: "Next Action",
  due_date: "Due Date",
  risk_level: "Risk",
  created_at: "Created At",
  priority: "Priority",
  watchers: "Watchers",
  float: "Submittal Float"
};

const fieldsToSkip: Array<string> = [];

function SubmittalSettings(props: {
  disabled: boolean;
  projectId: string;
  featureConfigurationsData: any;
  newProjectOffsetData: any;
  updateSettingsData: (data: any, tabTitle: string) => any;
  submittalheader: any;
  submittalDateBlockActions: Array<DateBlockInfoAction>;
}) {
  const {
    disabled,
    projectId,
    featureConfigurationsData,
    newProjectOffsetData,
    updateSettingsData,
    submittalheader,
    submittalDateBlockActions
  } = props;
  const [form] = Form.useForm();
  const [submitInProgress, setSubmitInProgress] = useState<boolean>(false);
  const {
    gqlClientForProject,
    tokenContents,
    projectDetails: projectInfo
  } = useContext(ProjectContext);
  const [showWarningMsg, setShowWarningMsg] = useState(false);
  const [projectOffset, setProjectOffset] = useState<any>();
  const [isFieldsEditable, setFieldsEditable] = useState<boolean>(false);
  const history = useHistory();
  const { tab3Id } = useParams() as any;
  const [selectedTab, setSelectedTab] = useState<string>(tab3Id || "dateblock");
  const isIntegrationMode = isProjectInIntegrationMode(
    projectInfo ? projectInfo.mode : 0
  );

  const [submittalOffsets, setSubmittalOffsets] =
    useState<Array<WorkflowDefaultData>>();

  const [updateProjectByIdMutation] = useMutation<any>(
    MUTATION_UPDATE_PROJECT_BY_ID,
    {
      client: gqlClientForProject
    }
  );

  const [updateProjectConfigMutation] = useMutation<any>(
    MUTATION_PROJECT_DEFAULT_CONFIG,
    {
      client: gqlClientForProject
    }
  );

  useEffect(() => {
    if (
      newProjectOffsetData &&
      newProjectOffsetData.workflow_defaults &&
      featureConfigurationsData &&
      featureConfigurationsData.project_feature_configurations
    ) {
      const defaultsMap: any = {};
      featureConfigurationsData.project_feature_configurations.forEach(
        (feature: any) => {
          if (feature?.feature_id === 1)
            defaultsMap.submittalThreshold = feature;
        }
      );
      newProjectOffsetData.workflow_defaults?.forEach((wf_offset: any) => {
        // Subamittal DB
        if (wf_offset.feature_id === 1) {
          const subOffsetsArr: Array<WorkflowDefaultData> =
            wf_offset.workflow_data ? [...wf_offset.workflow_data] : [];

          subOffsetsArr.push({
            offset_name: "sub_float",
            offset_value: wf_offset.float,
            milestone_name: "Submittal Float"
          });

          setSubmittalOffsets(subOffsetsArr);

          subOffsetsArr.forEach((sub_offset: WorkflowDefaultData) => {
            const key = sub_offset.offset_name.replaceAll(" ", "_");
            defaultsMap[key] = sub_offset.offset_value;
          });
        }
      });

      defaultsMap.spec_section = projectInfo?.spec_section ? "1" : "0";
      defaultsMap.field_mapping = submittalheader?.field_mapping || [];
      defaultsMap.actions = submittalDateBlockActions;
      form.setFieldsValue(defaultsMap);
      setProjectOffset(defaultsMap);
    }
  }, [
    featureConfigurationsData,
    form,
    newProjectOffsetData,
    projectInfo?.spec_section,
    submittalDateBlockActions,
    submittalheader
  ]);

  const cancelAction = () => {
    form.resetFields();
    setFieldsEditable(false);
    setSubmitInProgress(false);
  };

  const updateDateBlockDuration = async (data: any) => {
    const submittalOffsetsValue: any[] = [];
    submittalOffsets?.forEach((sub_offset: WorkflowDefaultData) => {
      const offset = sub_offset;

      if (offset.offset_name !== "On-site Preparation") {
        offset.offset_value = Number(
          data[offset.offset_name.replaceAll(" ", "_")]
        );
      }
      if (offset.offset_name !== "sub_float") {
        submittalOffsetsValue.push(offset);
      }
    });

    const submittalOffsetMap = {
      feature_id: 1,
      float: Number(data.sub_float),
      workflow_data: submittalOffsetsValue
    };

    const updateResponse = await updateSettingsData(
      submittalOffsetMap,
      "dateBlockDuration"
    );

    return updateResponse;
  };

  const updateRiskThreshold = (data: any) => {
    const updateResponse = updateSettingsData(
      {
        feature_id: 1,
        high_risk_threshold: data.submittalThreshold.high_risk_threshold,
        low_risk_threshold: data.submittalThreshold.low_risk_threshold
      },
      "riskThreshold"
    );

    return updateResponse;
  };

  const updateSubmittalNumbering = (data: any) => {
    const projectValues = {
      spec_section: data.spec_section === "1"
    };
    const variables = {
      projectId,
      projectValues
    };

    const updateResponse = updateProjectByIdMutation({
      variables
    });

    return updateResponse;
  };

  const updateRenameFields = (data: any) => {
    const variables = {
      feature_id: submittalheader?.feature_id,
      field_mapping: data?.field_mapping,
      date_block_action: null
    };

    const updateResponse = updateProjectConfigMutation({
      variables
    });

    return updateResponse;
  };

  const updateDateBlockActions = (data: any) => {
    const variables = {
      feature_id: submittalheader?.feature_id,
      field_mapping: null,
      date_block_action: data.actions
    };

    const updateResponse = updateProjectConfigMutation({
      variables
    });

    return updateResponse;
  };

  const onFinishForm = async (data: any) => {
    let updateResponse: any;

    setSubmitInProgress(true);
    if (selectedTab === "dateblock") {
      updateResponse = await updateDateBlockDuration(data);
    }

    if (selectedTab === "rename-field") {
      updateResponse = await updateRenameFields(data);
    }

    if (selectedTab === "risk-threshold") {
      updateResponse = await updateRiskThreshold(data);
    }

    if (selectedTab === "numbering") {
      updateResponse = await updateSubmittalNumbering(data);
    }

    if (selectedTab === "actions") {
      updateResponse = await updateDateBlockActions(data);
    }

    if (!updateResponse) {
      setSubmitInProgress(false);
      return;
    }

    Promise.all([updateResponse]).then((res: [any]) => {
      const errorMes = res.find((r) => r.errors && r.errors[0]?.message);
      if (errorMes) {
        message.error(errorMes.errors[0]?.message);
      } else {
        message.success("Default Settings are updated successfully.");
      }
      setFieldsEditable(false);
      setSubmitInProgress(false);
    });
  };

  const formRule: FormRule[] = [
    {
      validator: async (_, value) => {
        const regex = /^[0-9\b]+$/;
        if (!regex.test(value)) {
          setShowWarningMsg(true);
          return Promise.reject(
            new Error("Please enter duration in numbers only.")
          );
        }
        setShowWarningMsg(false);
        return Promise.resolve();
      }
    }
  ];
  const thresholdRules: FormRule[] = [
    {
      required: true,
      message: "Please enter threshold"
    },
    {
      validator: (_, value) => {
        const number = Number(value);
        if (!Number.isInteger(number) || Number.isNaN(number)) {
          return Promise.reject(
            new Error("Please enter threshold in numbers ( + or - ) only.")
          );
        }
        return Promise.resolve();
      }
    },
    {
      warningOnly: true,
      message: (
        <div className="text-[#3B3B3BCC]">
          Please note that negative value of float indicates the workflow is
          already delayed.
        </div>
      ),
      validator: async (_, value) => {
        const number = Number(value);
        if (number < 0) {
          return Promise.reject();
        }
        return Promise.resolve();
      }
    },
    {
      validateTrigger: "onSubmit",
      message:
        "High risk threshold has to be lower than the low risk threshold.",
      validator: () => {
        const threshold: ThresholdType =
          form.getFieldValue("submittalThreshold");
        if (
          Number(threshold.low_risk_threshold) <=
          Number(threshold.high_risk_threshold)
        ) {
          return Promise.reject();
        }
        return Promise.resolve();
      }
    }
  ];

  const renameFieldRules: FormRule[] = [
    {
      required: true,
      message: "Please enter field name",
      validator: async (_, value) => {
        if (value?.trim() === "") {
          return Promise.reject(new Error("Please enter field name"));
        }
        return Promise.resolve();
      }
    }
  ];

  const milestoneActionsRules: FormRule[] = [
    {
      required: true,
      message: "Please enter milestone action",
      validator: async (_, value) => {
        if (value?.trim() === "") {
          return Promise.reject(new Error("Please enter milestone action"));
        }
        return Promise.resolve();
      }
    }
  ];

  const settingsFormRules: { [key: string]: FormRule[] } = {
    defaultoffset: formRule,
    thresholdRules,
    renameFieldRules,
    milestoneActionsRules
  };

  const headerView = (title: string) => {
    if (title === "DISTRIBUTION GROUPS") {
      return (
        <div className="flex w-full items-center h-[40px] py-[2px] px-4">
          <div className=" text-[#3B3B3B] font-semibold text-sm">{title}</div>
        </div>
      );
    }
    return (
      <div className="flex w-full items-center justify-between h-[40px] py-[2px] px-4">
        <div className=" text-[#3B3B3B] font-semibold text-sm">{title}</div>

        {isFieldsEditable ? (
          <div className="flex space-x-4">
            <Button
              className="w-24"
              type="primary"
              htmlType="submit"
              loading={submitInProgress}
              disabled={submitInProgress || showWarningMsg}
            >
              Save
            </Button>
            <Button
              className="w-24"
              disabled={submitInProgress || showWarningMsg}
              onClick={cancelAction}
            >
              Cancel
            </Button>
          </div>
        ) : (
          <Button
            type="primary"
            className="w-24"
            disabled={disabled}
            onClick={() => setFieldsEditable(true)}
          >
            Edit
          </Button>
        )}
      </div>
    );
  };

  const dateBlockDurationInfo = (
    <div className="flex items-center w-full info-label pl-4 ">
      <div className="flex text-justify">
        Set the default durations of the Submittal workflow
      </div>
    </div>
  );

  const dateBlockDuration = (
    <div className="flex-col bg-white">
      {headerView("DATE BLOCK DURATION")}
      {dateBlockDurationInfo}
      <div className="flex justify-center w-full bg-white mt-10">
        {submittalOffsets ? (
          <div className="grid grid-cols-2 gap-x-20 w-3/5">
            {submittalOffsets?.map((wf: WorkflowDefaultData) => {
              if (wf.offset_name === "On-site Preparation") return "";
              return (
                <Form.Item
                  name={wf.offset_name.replaceAll(" ", "_")}
                  label={
                    wf.offset_name === "sub_float"
                      ? "SUBMITTAL FLOAT"
                      : wf.offset_name?.toUpperCase()
                  }
                  rules={settingsFormRules.defaultoffset}
                >
                  <Input type="text" disabled={!isFieldsEditable} />
                </Form.Item>
              );
            })}
          </div>
        ) : (
          <Spin />
        )}
      </div>
    </div>
  );

  const riskThresholdInfo = (
    <div className="flex items-start w-full info-label pl-4 ">
      <div className="space-y-1">
        <div className="flex text-justify">
          Indicate the value of float at which you would consider the workflow
          to be at High, Medium and Low risk. These values can later be set for
          individual Submittal.
        </div>
        <ul className="list-disc space-y-2">
          <li>Submittal is at high risk if {"Float <="} High Risk Threshold</li>
          <li>
            Submittal is at medium risk if High Risk Threshold {"< Float <="}{" "}
            Low Risk Threshold
          </li>
          <li>Submittal is at low risk if {"Float >"} Low Risk Threshold</li>
        </ul>
      </div>
    </div>
  );
  const riskThreshold = (
    <div className="flex-col">
      {headerView("RISK THRESHOLD")}
      {riskThresholdInfo}
      <div className="flex justify-center w-full mt-10 bg-white">
        <div className="grid grid-cols-2 gap-x-20  w-3/5">
          <Form.Item
            label="HIGH RISK THRESHOLD"
            name={["submittalThreshold", "high_risk_threshold"]}
            rules={settingsFormRules.thresholdRules}
          >
            <Input type="text" disabled={!isFieldsEditable} />
          </Form.Item>
          <Form.Item
            label="LOW RISK THRESHOLD"
            name={["submittalThreshold", "low_risk_threshold"]}
            rules={settingsFormRules.thresholdRules}
          >
            <Input type="text" disabled={!isFieldsEditable} />
          </Form.Item>
        </div>
      </div>
    </div>
  );

  const submittalNumberingInfo = (
    <div className="flex items-start w-full info-label pl-4">
      <div className="space-y-2">
        <span>Select the method of numbering of Submittal ID.</span>
        <ul className="list-disc space-y-2">
          <li>
            <span className="font-semibold">With spec section:</span> Submittal
            ID will contain the spec number as a prefix to the sequentially
            generated ID. Eg: 031000 - 1, 041000 - 2, etc.
          </li>
          <li>
            <span className="font-semibold">Without spec section:</span> IDs
            will be sequential numbers starting from 1.
          </li>
        </ul>
      </div>
    </div>
  );
  const submittalNumbering = (
    <div className="flex-col">
      {headerView("SUBMITTAL NUMBERING")}
      {submittalNumberingInfo}
      <div className="flex pl-4 w-full mt-9 bg-white">
        <Form.Item name="spec_section">
          <Radio.Group disabled={!isFieldsEditable}>
            <Radio value="0">Without Spec Section</Radio>
            <Radio value="1">With Spec Section</Radio>
          </Radio.Group>
        </Form.Item>
      </div>
    </div>
  );

  const submittalFieldsInfo = (
    <div className="flex items-center w-full info-label pl-4">
      <div className="flex text-justify">
        Rename the column headers of the Submittal log.
      </div>
    </div>
  );

  const submittalFields = (
    <div className="flex-col">
      {headerView("RENAME FIELDS")}
      {submittalFieldsInfo}
      <div className="flex justify-center w-full mt-10 overflow-y overflow-x-hidden max-h-[calc(100vh-250px)]">
        {projectOffset?.field_mapping ? (
          <div className="grid grid-cols-2 py-2 gap-x-10 w-4/5">
            <Form.List name="field_mapping">
              {(fields) => (
                <>
                  {fields.map((field, index) => {
                    const skipThisField = fieldsToSkip.includes(
                      projectOffset?.field_mapping[index].field_name
                    );

                    const titleKey =
                      submittalheader?.field_mapping[index]?.field_name;

                    // CIQ-2391 Remove fields which we do not allow renaming of from the settings page
                    const fieldWrapperClasses = classNames(
                      "grid grid-cols-2 justify-between items-center",
                      {
                        hidden:
                          skipThisField ||
                          !submittalheader?.field_mapping[index]
                            ?.allow_rename ||
                          !submittalHeaderTitleMap[titleKey]
                      }
                    );

                    return (
                      <div className={fieldWrapperClasses}>
                        <p className="col-span-1 titles mr-1">
                          {submittalHeaderTitleMap[titleKey] || ""}
                        </p>
                        <Form.Item
                          {...field}
                          className="col-span-1"
                          key="caption_en"
                          name={[field.name, "caption_en"]}
                          rules={settingsFormRules.renameFieldRules}
                        >
                          <Input type="text" disabled={!isFieldsEditable} />
                        </Form.Item>
                      </div>
                    );
                  })}
                </>
              )}
            </Form.List>
          </div>
        ) : (
          <Spin />
        )}
      </div>
    </div>
  );

  const distribuionGroupsInfo = (
    <div className="flex items-center w-full info-label pl-4">
      <div className="flex text-justify">
        Create Distribution Groups required to be added to Submittals.
      </div>
    </div>
  );

  const distribuionGroups = (
    <div className="flex-col">
      {headerView("DISTRIBUTION GROUPS")}
      {distribuionGroupsInfo}
      <DistributionGroup
        gqlClientForProject={gqlClientForProject}
        tokenContents={tokenContents}
      />
    </div>
  );

  const mileStoneActionsInfo = (
    <div className="flex items-center w-full info-label pl-4">
      <div className="flex text-justify">
        Define the actions to be taken to complete the below milestones.
      </div>
    </div>
  );
  const mileStoneActions = (
    <div className="flex-col">
      {headerView("MILESTONE ACTIONS")}
      {mileStoneActionsInfo}
      <div className="flex justify-center w-full mt-10 overflow-y overflow-x-hidden max-h-[calc(100vh-250px)]">
        {projectOffset?.actions ? (
          <div className="grid grid-cols-2 py-2 gap-x-10 w-[95%]">
            <Form.List name="actions">
              {(fields) => (
                <>
                  {fields.map((field, index) => {
                    const header = `${
                      projectOffset?.actions[index].step_name || ""
                    }`.replace("(Actual)", "");
                    return (
                      <div className="grid grid-cols-3 justify-between items-start">
                        <p className="col-span-1 mr-1 titles">{header}</p>

                        <Form.Item
                          {...field}
                          className="col-span-2"
                          key="caption_en"
                          name={[field.name, "action"]}
                          rules={settingsFormRules.milestoneActionsRules}
                        >
                          <TextArea
                            className="actionsLbl"
                            disabled={!isFieldsEditable}
                            rows={4}
                          />
                        </Form.Item>
                      </div>
                    );
                  })}
                </>
              )}
            </Form.List>
          </div>
        ) : (
          <Spin />
        )}
      </div>
    </div>
  );

  const tabItems = [
    {
      label: "Date Block Duration",
      key: "dateblock",
      children: dateBlockDuration
    },
    {
      label: "Distribution Groups",
      key: "distribution",
      children: distribuionGroups
    },
    {
      label: "Milestone Actions",
      key: "actions",
      children: mileStoneActions
    },
    {
      label: "Rename Fields",
      key: "rename-field",
      children: submittalFields
    },
    {
      label: "Risk Threshold",
      key: "risk-threshold",
      children: riskThreshold
    },
    {
      label: "Submittal Numbering",
      key: "numbering",
      children: submittalNumbering
    }
  ];

  const integrationTabItems = [
    {
      label: "Date Block Duration",
      key: "dateblock",
      children: dateBlockDuration
    },
    {
      label: "Milestone Actions",
      key: "actions",
      children: mileStoneActions
    },
    {
      label: "Rename Fields",
      key: "rename-field",
      children: submittalFields
    },
    {
      label: "Risk Threshold",
      key: "risk-threshold",
      children: riskThreshold
    }
  ];

  return (
    <div className="w-full h-full bg-white module-settings--tabs">
      <Form
        layout="vertical"
        form={form}
        initialValues={projectOffset}
        onFinish={onFinishForm}
        className="flex flex-col h-full"
        validateTrigger={["onSubmit", "onChange"]}
      >
        <Tabs
          animated
          tabPosition="left"
          tabBarStyle={{ width: "180px" }}
          onChange={(activeKey) => {
            history.push(
              `/project/${projectId}/settings/modules/submittals/${activeKey.toString()}`
            );
            setSelectedTab(activeKey);
            cancelAction();
          }}
          activeKey={selectedTab}
          items={!isIntegrationMode ? tabItems : integrationTabItems}
          tabBarGutter={2}
          destroyInactiveTabPane
        />
      </Form>
    </div>
  );
}
export default SubmittalSettings;
